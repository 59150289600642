<div class="preferences-selection-dialog">
    <h1 mat-dialog-title>{{ getTitleText() }}</h1>
    <mat-spinner *ngIf="(!preferences?.length)" diameter="40" strokeWidth="4" class="light-grey"></mat-spinner>
    <div mat-dialog-content>
        <div class="list-container" *ngIf="preferences?.length">
            <ng-container *ngFor="let preference of preferences;let i = index">
                <div class="list-item wl-cards-background-color"  [ngClass]="{'disabled': preference.disabled}" (click)="!preference.disabled && preferenceClick(preference)">
                    <div class="area-title">{{preference.text | translate}}</div>
                    <p class="area-description">
                        {{ this.data.params.areaDescriptions | areaDescriptionResolver:preference.value }}
                    </p>
                </div>
                <mat-divider *ngIf="i < preferences.length - 1"></mat-divider>
            </ng-container>
        </div>
    </div>
</div>
