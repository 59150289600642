import { Component, OnInit, OnDestroy, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';
import { TabitpayService } from '../tabit-pay/tabit-pay.service';
import { DialogsService } from '../../_core/dialogs.service';
import { StyleService } from '../../_core/style.service';
import { EntityService } from '../../_core/entity.service';

import { OrganizationsService } from '../../_core/organizations.service';
import { timer } from 'rxjs';

declare const $: any;

@UntilDestroy()
@Component({
    selector: 'qr-scanner',
    templateUrl: './qr-scanner.component.html',
    styleUrls: ['./qr-scanner.component.scss'],
    host: {
        'class': 'host-default'
    }
})
export class QRScannerComponent implements OnInit, OnDestroy {
    hasQRScanner: boolean = false;
    scanning: boolean = false;
    site: string = null;
    toggleFlashIcon: string = 'flash_on';

    constructor(
        public appService: AppService,
        public orderService: TabitpayService,
        public dialogsService: DialogsService,
        public router: Router,
        public elementRef: ElementRef,
        public styleService: StyleService,
        public entityService: EntityService,
        private organizationsService: OrganizationsService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private ngZone: NgZone,
        private http: HttpClient,
    ) {
        this.route.queryParams.subscribe(params => {
            if (params.site) this.site = params.site;
        });
    }

    ngOnInit() {
        if (window['QRScanner']) {
            this.hasQRScanner = true;
            // Added timeout
            setTimeout(() => {
                this.startScanner();
            }, 100);
        } else {
            // To simulate as if a QR Code has been succcessfully scanned - unccomment the following line:
            // this.simulateScanner();

            // To simulate the camera preview screen - uncomment the following two lines:
            // We need to let the router-outlet "leaver" to disappear first (otherwise we might see the leaving component in the background)
            timer(1000)
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.styleService.mainAppComponentTransparent = true;
                    this.scanning = true;
                    this.cdr.detectChanges();
                });

        }

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.removeBackgroundClasses();
                        this.appService.redirect(['/home/dashboard']);
                    })
                });
        }
    }

    simulateScanner(ev?) {
        // this.extractFromQR('https://qrstud.io/fwv1dtl');
        this.extractFromQR('https://tabit-web.tabit-dev-online.com/tabit-order?site=620e10c7315f496758e5d005&service=eatin&table=3');
        // this.extractFromQR('https://tabit-web.tabit-dev-online.com/tabit-order?siteName=misadassah&service=eatin');
    }

    startScanner() {
        const that = this;

        // Ensure QRScanner plugin is available
        if (!window['QRScanner']) {
            console.error('QRScanner plugin not available.');
            this.appService.toastr('QR Scanner plugin is not available.', { type: 'error' });
            return;
        }

        // Added ngZone to prevent the DOM being loaded as White screen
        this.ngZone.run(() => {
            setTimeout(() => {
                try {
                    window['QRScanner'].scan(displayScanContents);
    
                    // Make the webview transparent for the video preview
                    window['QRScanner'].show();
    
                    // Add a short delay before marking the component as transparent
                    timer(1000)
                        .pipe(untilDestroyed(this))
                        .subscribe(() => {
                            this.styleService.mainAppComponentTransparent = true;
                            this.scanning = true;
                            this.cdr.detectChanges();
                        });
                } catch (e) {
                    console.error('Error initializing QR Scanner:', e);
                    this.appService.toastr(e.message || 'Failed to initialize QR Scanner.', { type: 'error' });
                }
            }, 300);
        });

        function displayScanContents(err, text) {
            if (err) {
                that.extractFromQR(null, "MESSAGES.PLEASE_ENABLE_CAMERA");
                //alert('scan error' + JSON.stringify(err));
            } else {
                that.extractFromQR(text);
            }
        }
    }

    toggleFlash() {
        if (window['QRScanner']) {
            window['QRScanner'].getStatus((status) => {
                this.ngZone.run(() => {
                    console.debug('QR Scanner > toggleFlash > getStatus: ', status);
                    if (status && status.lightEnabled) {
                        // Turning the Flash off
                        window['QRScanner'].disableLight((err, status) => {
                            err && console.error('QR Scanner > toggleFlash > disableLight - Error:', err);
                            console.debug('QR Scanner > toggleFlash > disableLight - Status:', status);
                        });
                        // Updating the icon (we use it here and not inside the callback of the plugin to avoid ngZone issues and for less delay)
                        this.toggleFlashIcon = 'flash_on';
                    } else {
                        // Turning the Flash on
                        window['QRScanner'].enableLight((err, status) => {
                            err && console.error('QR Scanner > toggleFlash > enableLight - Error:', err);
                            console.debug('QR Scanner > toggleFlash > enableLight - Status:', status);
                        });
                        // Updating the icon (we use it here and not inside the callback of the plugin to avoid ngZone issues and for less delay)
                        this.toggleFlashIcon = 'flash_off';
                    }
                })
            });
        }
    }

    gotoManualEntry() {
        if (this.site) {
            console.debug('Tabit Pay > gotoManualEntry > Site: ', this.site);
            const siteDetails = {
                id: this.site,
            }
            this.dialogsService.toggleActionFrame('pay', siteDetails)
            // this.appService.redirect(['/pay/tabit-pay'], { queryParams: { site: this.site } });
        } else {
            this.appService.redirect(['/pay/sites']);
        }
    }

    extractFromQR(url, message?) {
        let args: any = {};

        this.ngZone.run(() => {
            try {
                if (message) {
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: 'MESSAGES.PLEASE_ENABLE_CAMERA',
                        primaryButtonText: 'redirect_to_device_settings'
                    }).then(() => {
                        if (window['cordova'] && window['cordova'].plugins && window['cordova'].plugins.diagnostic) {
                            window['cordova'].plugins.diagnostic.switchToSettings((success: any) => {
                                console.debug('extractFromQR > Cordova Plugin Diagnostic > Redirecting to device settings so the user can enable the camera - SUCCESS: ', success);
                            }, (error: any) => {
                                console.debug('extractFromQR > Cordova Plugin Diagnostic> Redirecting to device settings so the user can enable the camera - ERROR: ', error);
                            })
                        }
                    }).catch(() => {
                        // this.gotoManualEntry();
                        console.debug('Tabit Pay > Error enabling camera');
                    });

                    return;
                }

                // Try to preload qrstudio urls
                if (/qrstud/.test(url)) {
                    return this.unshortenUrl(url)
                    .then(res => {
                        this.extractFromQR(res);
                    }).catch(err => {
                        this.throwInvalidQrError();
                    })
                } else {
                    try {
                        url.split("?")[1].split("&").forEach((val, index) => {
                            let valArr = val.split("=");
                            args[valArr[0]] = valArr[1]
                        });
                    } catch (e) { }

                    // Redirect to Tabit Pay
                    if (args?.site && args?.oid) {
    
                        console.debug('Tabit Pay > extractFromQR', args);

                        // Fow WL apps we want to preserve the softDeleted logic
                        if (this.appService.skin) {
                            this.extractFromQRForWL(args);
                        } else {
                            this.redirectToTabitPay(args.site, args);
                        }

                    // Redirect to Tabit Order
                    } else if ((args?.site || args?.siteName || args?.sitename)) {
                        console.debug('Tabit Order > extractFromQR', args);
    
                        if (args?.siteName || args?.sitename) {
                            // Get the site by publicUrl
                            this.entityService.getSiteByPublicUrl(args?.siteName || args?.sitename)
                            .then(
                            (site: any) => {
                                if (!site?._id) return this.throwInvalidQrError();
                                args.site = site._id;
                                this.handleTabitOrderRedirect(args);
                            })
                        } else {
                            this.handleTabitOrderRedirect(args);
                        }
                    } else {
                        this.throwInvalidQrError();
                    }
                }
            } catch (e) {
                alert(e.description);
            }
        });
    }

    unshortenUrl(url: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.http
            .get(url, { observe: 'response', responseType: 'text' })
            .toPromise()
            .then(response => {
                const realUrl = response.url || url;
                resolve(realUrl);
            })
            .catch(error => {
                reject(error);
            });
        });
    }

    private extractFromQRForWL(args, target: 'Pay' | 'Order' = 'Pay') {
        this.organizationsService.fullOrganization(args.site)
        .subscribe(
            // Change redirect target by source
            (site) => target == 'Pay' ? this.redirectToTabitPay(site._id, args) : this.redirectToTabitOrder(site._id, args),
            (error) => {
                console.debug('Tabit Pay/Order > extractFromQR > this.organizationsService.fullOrganization 1 > Error: ', error);

                const organizatoinUrlIdentifier: string = this.appService.urlIdentifierRedirectFromError(error);
                if (organizatoinUrlIdentifier) {
                    this.organizationsService.fullOrganization(organizatoinUrlIdentifier).subscribe(
                        // Change redirect target by source
                        (site) => (target == 'Pay' ? this.redirectToTabitPay(site._id, args) : this.redirectToTabitOrder(site._id, args)),
                        (error) => {
                            console.debug('Tabit Pay > extractFromQR > this.organizationsService.fullOrganization 2 > Error: ', error);
                            this.throwInvalidQrError();
                        }
                    );
                } else {
                    this.throwInvalidQrError();
                }
            }
        );
    }

    private redirectToTabitPay(siteID: any, args: any) {
        if (siteID == args.site) {
            console.debug('Tabit Pay > extractFromQR > Site: ', siteID);
            this.orderService.redirect(siteID, args);

            // Redirect to dashboard in the app in order to prevent going back to qr component
            // without the ability to scan
            setTimeout(() => {
                this.appService.redirect(['/home']);
            }, 100)
            // this.appService.redirect(['/pay/tabit-pay'], { queryParams: { site: siteID, oid: args.oid, price: args.price } });
        } else {
            console.debug('Tabit Pay > extractFromQR > Site does not exist: ', siteID);
            this.throwInvalidQrError();
        }
    }

    private redirectToTabitOrder(siteID: any, args: any) {
        if (siteID == args.site) {
            console.debug('Tabit Pay > extractFromQR > Site: ', siteID);
            if (args.service) {
                const queryParams = {
                    site: siteID,
                    service: args.service
                };
                if (args.table) queryParams['table'] = args.table;

                this.appService.redirect(['/tabit-order'], { queryParams });
            } else {
                this.appService.redirect(['/app-site', args.site]);
            }
        } else {
            console.debug('Tabit Order > extractFromQR > Site does not exist: ', siteID);
            this.throwInvalidQrError();
        }
    }

    private handleTabitOrderRedirect(args) {
        // Fow WL apps we want to preserve the softDeleted logic
        if (this.appService.skin) {
            this.extractFromQRForWL(args, 'Order');
        } else {
            this.redirectToTabitOrder(args.site, args);
        }
    };

    removeBackgroundClasses() {
        this.styleService.mainAppComponentTransparent = false;
    }

    ngOnDestroy() {
        this.removeBackgroundClasses();
        this.scanning = false;

        if (window['QRScanner']) {
            window['QRScanner'].destroy(function (status) {
                console.debug('Close Module QR Status: ', status);
            });
        }
        console.log(`ngOnDestroy - QRScannerComponent`);
    }

    close() {
        this.appService.goBack();
    }

    throwInvalidQrError() {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.appService.mainMessage({
                dialogType: 'error',
                dialogText: "MESSAGES.INVALID_QR"
            }).then(res => {
                this.appService.goBack();
            });
        });
    }

}
