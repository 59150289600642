<div class="receipts-dialog">
    <h1 mat-dialog-title class="receipts-dialog-title">{{'rsv_management.receipt_dialog.title' | translate}}</h1>
    <mat-dialog-content class="receipts-dialog-content">
        <div class="receipt-item" *ngFor="let receipt of receipts; let index = index">
            <div class="receipt-link" (click)="receiptClick(receipt.url)">
                <span class="payment-type">{{ 'rsv_management.receipt_dialog.' + receipt.paymentType | translate }}</span>
                <span>{{ 'rsv_management.receipt_dialog.amount' | translate: { amount: receipt.amount, currency_symbol: currencySymbol } }}</span>
                <span>{{ 'rsv_management.receipt_dialog.date' | translate: { date: receipt.date } }}</span>
            </div>
            <mat-divider *ngIf="index < receipts?.length - 1"></mat-divider>
        </div>
    </mat-dialog-content>
</div>