import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DialogsService } from '../_core/dialogs.service';

import { AppService } from '../app.service';
import { EntityService } from './entity.service';
import { OccasionsService } from './occasions.service';
import { EntityUtilsService } from './entity.utils.service';
import { OrganizationsService } from './organizations.service';
import { SearchDetails } from './OrganizationBucket';

import { bookingStrings } from './static/translations.booking';
import { TabitbookLicenseDialogComponent } from '../tabit-book/tabitbook-license-dialog/tabitbook-license-dialog.component';

import { get, each, merge, range, map, pick, every, some, assignIn, orderBy } from 'lodash-es';
import moment from 'moment';
import { Observable } from 'rxjs';

@Injectable()
export class BookService {
	appConfig: any = environment.appConfig;
	public daysMap: any = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

	constructor(
		public dialog: MatDialog,
		private http: HttpClient,
		public appService: AppService,
		public entityService: EntityService,
        public utilsService: EntityUtilsService,
		public dialogsService: DialogsService,
        private organizationsService: OrganizationsService,
		private occasionsService: OccasionsService,
        private ngZone: NgZone,
	) {

	}

	$storage: any = {}
	orgTimeSlots: any = {};
	crossOrgSearchResult: any = null;
	crossOrgSearchDetails: SearchDetails = null;

	private tgmEnvironmentFromSite(organization): string {
        console.debug('Book Service > tgmEnvironmentFromSite: ', organization);

        if (!organization || !organization.environment) {
			return this.appConfig.bookingAPI;
		}

		if (/beta/.test(organization.environment)) {
            return this.appConfig.bookingAPIBeta;
		} else {
			return this.appConfig.bookingAPI;
		}
	}

	start(site, organization, presetBookingDetails, occasions?: any[]) {
		let that = this;

		let daysTime = {}
		let timeIncrement = site.table_lookup_time_increments || 15;
		each(site.booking_windows, day => {
			daysTime[day[0]] = that.generateDaySlots(day[1], timeIncrement);
		});


		let excludedDays = site.days_to_exclude || [];
		// excludedDays.sun = ["fri", "sat"];
		let dates_to_exclude = {};
		each(site.dates_to_exclude, date => {
			dates_to_exclude[date[0]] = date[1] || [];
		});

        let { bookingPreferences, preferencesMap } = this.getBookingPreferences(site);
        let occasionsDateMapped = this.occasionsService.getOccasionsDateMapped(occasions || []);

		let dates = [];
		let current = moment();
		let firstActive;
		for (let i = 0; i < 30; i++) {
			let dayN = current.day();
			let dayS = this.daysMap[dayN];
			if (i == 0) {
				excludedDays = excludedDays[dayS] || [];
			}

			let dateS = current.format('YYYY-MM-DD');
			let dateO: any = {
				enabled: false,
				date: current.toDate(),
				day: dayS,
                dayN: dayN,
                occasions: occasionsDateMapped.get(dateS) || [],
			}
			let excludeIndex = excludedDays.indexOf(dayS);
			if (dates_to_exclude[dayS]) {
				let slots = that.generateDaySlots(excludedDays[dayS], timeIncrement);
				if (slots.length) {
					dateO.enabled = true;
					dateO.slots = slots;
				}
			} else if (excludeIndex != -1) {
				excludedDays.splice(excludeIndex, 1);
			} else {
				let dataDay = daysTime[dayS] || daysTime['default'];
				if (dataDay && dataDay.length) {
					dateO.enabled = true;
					dateO.slots = dataDay;
				}
			}

			if (i === 0 && dateO.enabled) {
				let reserveFromBuffer = (site.future_reservation && site.future_reservation.reserve_from_now_minutes_buffer || 30);
				let mm = that.getMinutesFromMoment(current) + reserveFromBuffer;
				let todaySlots = [];
				each(dateO.slots, slot => {
					if (slot.value > mm) todaySlots.push(slot);
				});
				if (todaySlots.length) {
					dateO.slots = todaySlots;
				} else {
					dateO.enabled = false;
					delete dateO.slots;
				}
			}

			if (dateO.enabled) {
				if (!firstActive) firstActive = dateO;
			}

            if (dateO.slots && dateO.slots.length && dateO.enabled && dateO.occasions.length) {
                dateO.slots = dateO.slots.map(slot => {
                    return {
                        ...slot,
                        occasions: this.occasionsService.getOccasionsForTimeSlot(slot.text, timeIncrement, dateO.date, dateO.occasions),
                    }
                });
            }

            dates.push(dateO);
			current = current.add(1, 'days');
		}

		let max_group_size = site.max_group_size || 10;
		let dinersTrans = this.appService.translate('TGM.DINERS');
		let bookingDiners = Array.from(new Array(max_group_size), (v, index) => {
			let n = index + 1;
			let member: any = { value: n };
			if (n === 1) member.text = this.appService.translate('TGM.ONE_DINER');
			else if (n == max_group_size) member.text = n + " + " + dinersTrans;
			else member.text = (n) + " " + dinersTrans;
			member.textSmall = n;
			return member;
		});

		Object.assign(this.$storage, {
			enablePreferences: !site.future_reservation.hide_preference_field,
			preferencesRequired: site.future_reservation.require_preference_field,
			bookForm: {
				diners: bookingDiners[1],
				date: firstActive,
				time: firstActive.slots[0],
				preference: site.future_reservation.require_preference_field ? {
					value: 'choose_preference',
					text: this.translate('booking.search.choose_preference')
				} : bookingPreferences[0]
			},
            bookFormChanged: {
                diners: false,
                date: false,
                time: false,
                preference: false,
            },
			organization: organization,
			site: site,
			bookingDates: dates,
			bookingTimes: firstActive.slots,
			bookingDiners: bookingDiners,
			bookingPreferences: bookingPreferences,
			preferencesMap: preferencesMap,
			depositConfig: get(site, 'reservation_form.deposit', {}),
			deposit: {
				exp: {}
			},
			bookDetails: {
				"customer": {
					"first_name": get(this.appService.user, 'loyaltyCustomer.FirstName', ''),
					"last_name": get(this.appService.user, 'loyaltyCustomer.LastName', ''),
					"phone": get(this.appService.user, 'loyaltyCustomer.Mobile', ''),
					"email": get(this.appService.user, 'loyaltyCustomer.Email', ''),
				},
				"notes": "",
                "tags": [],
				"send_notification": {
					"event_type": "online_booking_created"
				}
			}
        });

        if (presetBookingDetails) {
            merge(this.$storage, this.getBookFormUpdatesFromPreset(presetBookingDetails));
        }
        console.debug('=== Book Service > Start - STORAGE: ', this.$storage, presetBookingDetails);
	}

    startWithoutSite() {

        const oneDinerText = this.appService.translate('TGM.ONE_DINER');
        const dinersText = this.appService.translate('TGM.DINERS');

        let availableDates = [];

        const defaultSlots = this.generateFullDaySlots();

        const datePickerCustomStartDay = moment('2024-10-01', 'YYYY-MM-DD');
        const day = moment().isBefore(datePickerCustomStartDay) ? datePickerCustomStartDay : moment();
        let today = moment().isSameOrAfter(datePickerCustomStartDay);
        const more90Days = moment(day).add(3, 'months');
        while(day.isBefore(more90Days)) {
            if (moment(day).startOf('day').isSame(moment().startOf('day')) && parseInt(moment().format('HHmm')) > 2200) {
                // Our time selection list ends at 23:00.
                // We add 1.5 hours to the current time - and that's our "default time".
                // So, after 22:00 we must start our dates list from "tomorrow".
            } else {
                availableDates.push({
                    enabled: true,
                    date: moment(day).toDate(),
                    // day: dayS,
                    // dayN: dayN,
                    occasions: [],
                    defaultSlots,
                    slots: today ? this.generateFullDaySlots(true) : defaultSlots,
                });
            }
            today = false;
            day.add(1, 'day');
        }

        const bookingDiners = range(1, 10).map(n => {
            const member: any = { value: n };
            if (n === 1) member.text = oneDinerText;
            else if (n == 10) member.text = n + " + " + dinersText;
            else member.text = (n) + " " + dinersText;
            member.textSmall = n;
            return member;
        });

        let previousDiners, previousDate, previousTime = null;

        if (this.$storage && this.$storage.bookForm) {
            const { diners, date, time } = this.$storage.bookForm;

            previousDiners = diners;
            previousDate = date;
            previousTime = time;
        }

        Object.assign(this.$storage, {
            enablePreferences: false,
            preferencesRequired: false,
            bookForm: { diners: previousDiners || bookingDiners[1], date: previousDate || availableDates[0], time: previousTime || availableDates[0].slots[0] },
            bookFormChanged: {
                diners: false,
                date: false,
                time: false,
                preference: false,
            },
            bookingDates: availableDates,
            bookingTimes: previousDate?.slots || availableDates[0].slots,
            bookingDiners: bookingDiners,
            bookDetails: {
                customer: {
                    first_name: get(this.appService.user, 'loyaltyCustomer.FirstName', ''),
                    last_name: get(this.appService.user, 'loyaltyCustomer.LastName', ''),
                    phone: get(this.appService.user, 'loyaltyCustomer.Mobile', ''),
                    email: get(this.appService.user, 'loyaltyCustomer.Email', ''),
                },
                notes: "",
                tags: [],
                send_notification: {
                    event_type: "online_booking_created"
                }
            }
        });

        console.debug('=== Book Service > Start without Site - STORAGE: ', this.$storage);
    }

    getBookingPreferences(bookingData) {
        let preferencesMap: any = {}
        const bookingPrefs = bookingData.preferences || bookingData.reservation_preferences || [];

        if (!bookingPrefs[0] && !bookingData.future_reservation.require_preference_field) bookingPrefs.push('first_available');
        // Preference field is required
        else if (bookingData.future_reservation.require_preference_field) {
            // Remove "First Available" Option
            const index = bookingPrefs.indexOf('first_available');
            if (index >= 0) bookingPrefs.splice(index, 1);
        } else if (!bookingData.future_reservation.require_preference_field) {
            // Remove "First Available" Option
            const index = bookingPrefs.indexOf('first_available');
            if (index < 0) bookingPrefs.push('first_available');
        }

        const bookingPreferences = map(bookingPrefs, pref => {
            const transText = this.appService.translate(`booking.search.${pref}`, null, get(bookingData, 'strings.rsv'));

            preferencesMap[pref] = transText;

            return {
                value: pref,
                text: transText
            }
        });

        return { bookingPreferences, preferencesMap };
    }

    public validBookingDetails(bookingDetails) {
        return !!(
            bookingDetails &&
            bookingDetails.bookForm &&
            bookingDetails.bookForm.date &&
            bookingDetails.bookForm.time &&
            bookingDetails.bookForm.diners
        );
    }

    getBookFormUpdatesFromPreset(presetBookingDetails) {
        const extend = pick(presetBookingDetails, ['bookForm', 'bookFormChanged']);
        return extend;
    }

	resetStorage() {
		this.$storage = {};
		this.orgTimeSlots = {};
		this.crossOrgSearchResult = null;
        this.crossOrgSearchDetails = null;
    }

	//------------------------------------------------------------------------------>
	// utilities
	//------------------------------------------------------------------------------>

	getMinutes(s) {
		let arr: any = s.split(":");
		return Number(arr[0]) * 60 + Number(arr[1]);
	}

	getMinutesFromMoment(current: moment.Moment) {
		var hh = current.get('hour');
		var mm = current.get('minute');
		return hh * 60 + mm;
	}

	parseMinutes(n) {
		if (n >= 1440) n -= 1440;
		let h = Math.floor(n / 60) + "";
		let m = n % 60 + "";
		return h.padStart(2, '0') + ":" + m.padStart(2, '0');
	}

	generateDaySlots(day, timeIncrement) {
		let that = this;
		let slots = []
		each(day, slot => {
			let prevTime = slots[slots.length - 1];
			if (prevTime) prevTime = prevTime.value;
			else prevTime = 0
			slots = slots.concat(that.generateSlots(slot[1], prevTime, timeIncrement));
		});
		return slots;
	}

	generateSlots(o, prevTime, timeIncrement) {
		let slots = [];
		let that = this;
		let timeStart = that.getMinutes(o.from);
		let timeEnd = that.getMinutes(o.to);
		if (timeEnd < timeStart) timeEnd += 1440;

		let op = 0;
		if (prevTime > timeStart) {
			op = 1440;
		}

		while (timeStart < timeEnd) {
			slots.push({
				text: that.parseMinutes(timeStart),
				value: timeStart + op
			});
			timeStart += timeIncrement;
		}
		return slots;
	}

    generateFullDaySlots(today?: boolean) {
        let slots = [];
        let now = moment();
        let toHalfHourRound = 30 - (now.minute() % 30);
        let firstSlotTime = (
            today ?
            moment().add(toHalfHourRound, 'minutes').add(1, 'hours').startOf('minute') :
            moment().hours(8).startOf('hour')
        );
        let dayBeginning = moment(firstSlotTime).startOf('day');
        let lastSlotTime = moment(dayBeginning).endOf('day');
        let slotTime = moment(firstSlotTime);
        while(slotTime.isBefore(lastSlotTime)) {
            slots.push({
                text: slotTime.format('HH:mm'),
                value: slotTime.diff(dayBeginning, 'minutes'),
            });
            slotTime.add(30, 'minutes');
        }
        return slots;
    }

    public isDayBlocked(day): boolean {
        return !!day && !!day.slots && !!day.slots.length && every(day.slots, slot => this.isSlotBlocked(slot));
    }

    public isSlotBlocked(slot): boolean {
        return !!slot && !!slot.occasions && !!slot.occasions.length && some(slot.occasions, occasion => occasion.occasion_details.online_booking.block);
    }

	translate(path: string, args?, strOverrides?: any): string {
        if (!path) return "";

        let translation = get(merge({}, bookingStrings[this.appConfig.locale], (strOverrides && strOverrides[this.appConfig.locale]) || {}), path) || path;
        each(args, (val, key) => {
            translation = translation.replace(`{{${key}}}`, val);
        });
        return translation;
    }

	showLicense() {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.dialog.open(TabitbookLicenseDialogComponent, {
                data: {}
            });
        });
	}

	//------------------------------------------------------------------------------>
	// http calls
	//------------------------------------------------------------------------------>

	postTempReservation(req) {
		return this.post('/rsv/booking/temp-reservations', req, {}, this.tgmEnvironmentFromSite(this.$storage.site));
	}

	deleteTempReservation(reservationId, organization, site): Observable<any> {
		return this.http.delete(`${this.tgmEnvironmentFromSite(site)}/rsv/management/${reservationId}/customer_cancelled`, assignIn({}, this.appService.appHttpOptions, { params: {organization} }));
	}

	putCustomerDetails() {
		// Update SMS Notification Key For Standby Reservations
		if (this.$storage.reservation.standby_reservation && this.$storage.bookDetails.send_notification) this.$storage.bookDetails.send_notification.event_type = 'online_booking_created_standby';

		return this.update(`/rsv/booking/temp-reservations/${this.$storage.reservation._id}?organization=${this.$storage.organization}`, this.$storage.bookDetails, null, this.tgmEnvironmentFromSite(this.$storage.site));
	}

	//------------------------------------------------------------------------------>
	// http utils
	//------------------------------------------------------------------------------>

    public post(url: string, payload?: object, options?: object, endPoint?: any): Promise<any> {
		return new Promise((resolve, reject) => {
            this.http.post((endPoint || this.appConfig.bookingAPI) + url, payload || {}, options || {})
				.subscribe(
					(results: any) => {
						resolve(results);
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	public update(url, payload?, _headers?, endPoint?): Promise<any> {
		return new Promise((resolve, reject) => {
            console.debug('Book Service > Update: ', endPoint);
			this.http.put((endPoint || this.appConfig.bookingAPI) + url, payload || {})
				.subscribe(
					(results: any) => {
						resolve(results);
					},
				(err) => {
						reject(err);
					}
				);
		});
	}

    public putReservation(reservation) {
        if (this.appService.reservations) this.appService.reservations.push(reservation);
        else this.appService.reservations = [reservation];

        this.appService.reservations = orderBy(this.appService.reservations, reservation => {
            if (!reservation.reservation_details || !reservation.reservation_details.reserved_from) return reservation.created;
            return reservation.reservation_details.reserved_from;
        }, 'asc');
	}

	public openRSV(reservation) {
        let queryParams = {
            orgId: reservation.organization,
            reservationId: reservation._id
        }

        return this.appService.redirect([`${this.appService.getTranslatedRoute('reservation')}/management`], { queryParams });
    }

    public populateDisabledTimeSlots(timeSlots: any) {
        if (!timeSlots) return;

        let requestedTimestamp = moment(this.crossOrgSearchDetails.booking.timestamp);
        let nextTimestamp = moment(this.crossOrgSearchDetails.booking.timestamp).add(30, 'minutes');
        let prevTimestamp = moment(this.crossOrgSearchDetails.booking.timestamp).subtract(30, 'minutes');

        let timeSlotsWithDisabled = [
            {timestamp: prevTimestamp.toISOString(), class_name: 'disabled'},
            {timestamp: requestedTimestamp.toISOString(), class_name: 'disabled'},
            {timestamp: nextTimestamp.toISOString(), class_name: 'disabled'},
        ];

        timeSlots.forEach(timeSlot => {
            if (moment(timeSlot.timestamp).isSame(requestedTimestamp)) {
                timeSlotsWithDisabled[1] = timeSlot;
            } else if (moment(timeSlot.timestamp).isSame(nextTimestamp)) {
                timeSlotsWithDisabled[2] = timeSlot;
            } else if (moment(timeSlot.timestamp).isSame(prevTimestamp)) {
                timeSlotsWithDisabled[0] = timeSlot;
            }
        });

        //console.log('=== crossOrgSearchDetails: ', this.crossOrgSearchDetails, timeSlots, timeSlotsWithDisabled);

        return timeSlotsWithDisabled;
    }

	async getLinkForTimeSlotOrRedirect(event: Event, timeSlot: any, areaDescriptions = {}, siteId: string) {
        event.stopPropagation();

        if (timeSlot && timeSlot.class_name == 'disabled') return;

        const organization = this.organizationsService.getOrganization(siteId);
        if (!organization) return console.error('No such organization for booking choice:', siteId);
        if (!organization.bookingData) return console.error(`Organization: ${siteId}, (${organization.name}) has no bookingData available`);

        let seatsCount = parseInt(get(this.$storage, 'bookForm.diners.value')) || '';
        let timestamp = timeSlot.timestamp;
        let source = 'tabit';

        let queryParams: any = {
            orgId: siteId,
            locale: this.appService.localeId,
            source: source,
            seats_count: seatsCount,
            timestamp: timestamp,
            createReservation: true
        };

        if (timeSlot.standby) queryParams.standby = true;

        // 2020-02-06 [ ! ] Important Note
        // On iOS the window.open gets blocked if triggered after the .subscribe
        // Only if the window.open is triggered directly from (onclick) it works and doesn't get blocked.
        // So, we need to pass the link into the dialog, and let it trigger from there upon onclick.

        if (!organization.bookingData.future_reservation.hide_preference_field || timeSlot?.special_areas_exist) {
            const { bookingPreferences } = this.getBookingPreferences(organization.bookingData);

            if (get(organization.bookingData, `strings.rsv.${this.appService.localeId}`))
                this.appService.updateTranslations(this.appService.removeNullProperties(organization.bookingData.strings.rsv[this.appService.localeId]));

            return new Promise<string | void>((resolve) => {
                this.ngZone.run(() => {
                    this.dialogsService.showPreferencesSelectionDialog({
                        rsv_url: this.tgmEnvironmentFromSite(organization),
                        rsv_preferences: bookingPreferences,
                        future_reservation: organization.bookingData.future_reservation,
                        params: {
                            organization: siteId,
                            seats_count: seatsCount,
                            timestamp,
                            source: this.appService.skin ? this.appService.skin : 'tabit',
                            areaDescriptions,
                        }
                    }).then(async preference => {
                        if (!preference) return;
    
                        queryParams.preference = preference;

                        if (window['cordova']) {
                            this.appService.redirect([this.appService.getTranslatedRoute('reservation')], { queryParams });
                        } else {
                            resolve(this.appService.getRedirectUrl([this.appService.getTranslatedRoute('reservation')], { queryParams }));
                        }
                    }).finally(() => {
                        this.appService.stopBlock();
                    });
                });
            });
        // Without preference_field || special_areas_exist
        } else  {
            if (window['cordova']) return this.appService.redirect([this.appService.getTranslatedRoute('reservation')], { queryParams });
            return this.appService.getRedirectUrl([this.appService.getTranslatedRoute('reservation')], { queryParams });
        }
    }

    getCrossOrgsSearchTime() {
        let dateWithCorrectDay = get(this.$storage, 'bookForm.date.date');
        let timeString = get(this.$storage, 'bookForm.time.text');

        if (!dateWithCorrectDay || !timeString || !timeString.match(/^\d\d:\d\d$/)) {
            throw new Error('incorrect date / time data from cross orgs booking input selectors');
        }

        let [hours, minutes] = timeString.split(':');

        return moment(dateWithCorrectDay).hours(hours).minutes(minutes).startOf('minute');
    }

    getCrossOrgsSearchSeatCount() {
        let diners = parseInt(get(this.$storage, 'bookForm.diners.value'));
        if (isNaN(diners)) throw new Error('incorrect number of diners data from booking selector');
        return diners;
    }

}
