import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

import { get, isArray } from 'lodash-es';

interface Product {
    name: string,
    id: string,
    price: string,
    brand: string,
    category: string,
    variant: string,
    quantity: number,
    coupon?: string
}

interface Item {
    item_name: string,
    item_id: string,
    affiliation?: string, // A product affiliation to designate a supplying company or brick and mortar store location
    currency?: string, // If set, event-level currency is ignored
    item_brand?: string, // The brand of the item
    item_category?: string, // The category of the item. If used as part of a category hierarchy or taxonomy then this will be the first category
    item_variant?: string, // The item variant or unique code or description for additional item details/options
    price?: number,
    location_id?: any,
    quantity: number,
}

interface PixelLayer {
    event: 'ViewContent' | 'AddToCart' | 'InitiateCheckout' | 'AddPaymentInfo' | 'Purchase' | 'Search' | 'Lead',
    currency: string,
    value?: number,
    content_name?: string,
    content_type?: string,
    content_ids? : any,
    address?: string,
    service_type?: string,
    site_name?: string, 
}

interface GA4BaseLayer {
    currency: string,
    payment_type?: string,
    transaction_id?: string,
    value?: any,
    shipping?: number,
    tax?: number,
    items?: [Item]
}

export enum ANALYTICS_LAYER_TYPES {
    add_to_cart,
    remove_from_cart,
    view_item,
    begin_checkout,
    checkout_progress,
    add_payment_info,
    purchase,
    search,
    generate_lead
}

export enum PIXEL_EVENT_TYPES {
    ViewContent,
    AddToCart,
    InitiateCheckout,
    AddPaymentInfo,
    Purchase,
    Search,
    Lead
}

@Injectable({
    providedIn: 'root'
})

export class MarketingService {

    private analyticsEventTypesMap = {
        0: 'add_to_cart',
        1: 'remove_from_cart',
        2: 'view_item',
        3: 'begin_checkout',
        4: 'checkout_progress',
        5: 'add_payment_info',
        6: 'purchase',
        7: 'search',
        8: 'generate_lead',
    }

    private pixelEventTypesMap = {
        0: 'ViewContent',
        1: 'AddToCart',
        2: 'InitiateCheckout',
        3: 'AddPaymentInfo',
        4: 'Purchase',
        5: 'Search',
        6: 'Lead',
    }

    constructor(
        public appService: AppService,
    ) { }

    public initFBPixel() {
        (function (f: any, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }; if (!f._fbq) f._fbq = n;
            n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        (window as any).fbq.disablePushState = true; //not recommended, but can be done
    }

    public prepareGA4LayerAndPushIfNeeded(data: any, type: ANALYTICS_LAYER_TYPES, $storage: any) {
        if (!data) return console.error('=== Marketing Service | GA4 data was not provided!');

        this.setDataForAnalyticsGA4AndPush(data, type, $storage);
    }

    public preparePixelAndPushIfNeeded(data: any, type: PIXEL_EVENT_TYPES, $storage?: any, customPixelId?: string) {
        if (!data && !customPixelId) return console.error('=== Marketing Service | Pixel data was not provided!');

        const pixelId = customPixelId || get(this.getMarketingFromStorage($storage), 'pixelId');

        if (pixelId) {
            this.setDataForPixelAndPush(data, type, $storage);
        }
    }

    public onlineBookingAnalyticsPush(type: ANALYTICS_LAYER_TYPES) {
        // Since we're using the same gtag.js script for multiple GA4 properties, we can either specify the measurement ID for each event, or to ignore it, and then it will be applied to all configured tags.
        // This is why this funciton is not using the measurement ID.

        // Official GA4 Events Documentation:
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
        window['gtag']('event', this.analyticsEventTypesMap[type]);

        console.debug(`Marketing Service (RSV) > pushed GA4 ${this.analyticsEventTypesMap[type]} with gtag function`);
    }

    private setDataForAnalyticsGA4AndPush(data, type, storage) {
        // Since we're using the same gtag.js script for multiple GA4 properties, we can either specify the measurement ID for each event, or to ignore it, and then it will be applied to all configured tags.
        // This is why this funciton is not using the measurement ID.

        const layerEventType = this.analyticsEventTypesMap[type];
        const layerEventObject: GA4BaseLayer = {
            currency: get(this.appService, 'appConfig.currencyCode', 'ILS'),
            items: this.getItems(data, type, storage),
        }

        // Specific types that need special properties
        switch (type) {
            case ANALYTICS_LAYER_TYPES.add_to_cart:
                layerEventObject.value = (data.total || 0).toFixed(2);

                break;
            case ANALYTICS_LAYER_TYPES.add_payment_info:
                layerEventObject.payment_type = data.paymentType;
                layerEventObject.value = (data.amount || 0).toFixed(2);

                break;
            case ANALYTICS_LAYER_TYPES.purchase:
                layerEventObject.transaction_id = data.id || data._id;
                layerEventObject.value = ((data.totals.totalAmount || data.totals.grossSales) / 100).toFixed(2);

                break;
        }

        // Official GA4 Events Documentation:
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
        window['gtag']('event', layerEventType, layerEventObject);

        console.debug(`Marketing Service > pushed GA4 event ${layerEventType} with datalayer:`, layerEventObject);
    }

    private setDataForPixelAndPush(data, type, $storage) {
        const layer: PixelLayer = {
            event: this.pixelEventTypesMap[type],
            currency: get(this.appService, 'appConfig.currencyCode', 'ILS'),
            content_ids: [],
        }

        // Specific types that need special properties
        switch (type) {
            case PIXEL_EVENT_TYPES.ViewContent:
            case PIXEL_EVENT_TYPES.AddToCart:
                layer.value = (data.viewPrice || data.price);
                layer.content_name = data.name;
                layer.content_type = get(data, '_item.category.dishRole');
                layer.content_ids.push(data.offerId || data.offer);

                break;
            case PIXEL_EVENT_TYPES.InitiateCheckout:
                layer.value = data.order.total || data.order.realTotal;
                layer.content_name = 'Initiate Checkout';
                layer.content_type = get(data, 'orderMode');
                layer.content_ids = data.basket.map(item => item.offerId ||item.offer);

                break;
            case PIXEL_EVENT_TYPES.AddPaymentInfo:
                layer.content_name = data.name;
                layer.content_type = data.paymentType || 'Payment';
                layer.content_ids.push(data._id || data.id);

                break;
            case PIXEL_EVENT_TYPES.Purchase:
                layer.value = (data.totals.totalAmount || data.totals.grossSales) / 100;
                layer.content_name = get(data, 'serviceType', $storage?.orderMode);
                layer.content_type = get(data, 'serviceType', $storage?.orderMode);
                layer.content_ids = ([] as any).push(data._id || data.id);
                layer.address = get($storage, 'site.address', get($storage, 'rosConfig.config.address'));
                layer.service_type = get(data, 'serviceType', $storage?.orderMode);
                layer.site_name = get($storage, 'site.name', get($storage, 'rosConfig.config.name'));

                break;
        }

        this.sendFBPixelData(layer);
    }

    private getItems(data, type, storage): any {
        const itemsToProcess = ['add_payment_info', 'purchase'].includes(this.analyticsEventTypesMap[type]) ? storage.basket : data;
        let items = [];
        let item: Item;
        if (isArray(itemsToProcess) && itemsToProcess.length) {
            itemsToProcess.forEach(offer => {
                item = this.setItem(offer, storage);
                if (item) items.push(item);
            })
        } else {
            item = this.setItem(itemsToProcess, storage);
            if (item) items.push(item);
        }

        return items;
    }

    private setItem(layer, data?): Item {
        if (!layer) return;

        const item: Item = {
            item_name: layer.name,
            item_id: layer.offer || layer.offerId || layer._id,
            price: (layer.price || 0).toFixed(2),
            item_brand: layer._offer?.sectionName,
            item_category: layer._item?.category?.id || layer._item?.category?._id,
            item_variant: layer._item?.name,
            quantity: layer.quantity || 1,
            affiliation: get(data, 'site.name', get(data, 'rosConfig.config.name')),
            location_id: get(data, 'orderMode'),
        };

        return item;
    }

    private sendFBPixelData(layer) {
        if (!layer) return;
        console.debug('Marketing Service > pushed Pixel event with fbq function:', layer);
        if (window['fbq']) {
            window['fbq']('track', layer.event, layer);
        }
    }

    getMarketingFromStorage($storage): any {
        const marketing = $storage.isOrganization ? get($storage.organization, 'marketing', $storage.marketing) : get($storage.rosConfig, 'marketting', get($storage.site, 'marketting'));
        return marketing;
    }

}
