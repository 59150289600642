import { AfterViewInit, Component, Inject, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { get } from 'lodash';

import { AppService } from '../../app.service';
import { AuthService } from '../../_core/auth.service';
import { SignInByPhoneVerifyCodeFormComponent } from '../sign-in-by-phone-verify-code-form/sign-in-by-phone-verify-code-form.component';

@Component({
    selector: 'app-verify-code-dialog',
    templateUrl: './verify-code-dialog.component.html',
    styleUrls: ['./verify-code-dialog.component.scss']
})
export class VerifyCodeDialogComponent implements AfterViewInit {
	@ViewChild(SignInByPhoneVerifyCodeFormComponent, { static: false }) verifyCodeForm: SignInByPhoneVerifyCodeFormComponent;
    
    public formModel = { code: '' };
    public authenticating: boolean = false;
    public resending: boolean = false;

    constructor(
        public appService: AppService,
        public authService: AuthService,
        public ngZone: NgZone,
        public dialogRef: MatDialogRef<VerifyCodeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngAfterViewInit(): void {
        if (this.verifyCodeForm) {
            this.verifyCodeForm.startResendCodeCountdown();
        }
    }

    verifyCode(ev) {
        if (!ev.code) return;
        this.authenticating = true;

        const args = {
            code: ev.code,
            phone: this.data.phone,
        };

        return this.authService.signInByPhoneVerifyCode$(args).subscribe(
			(response: any) => {
                // Storing the Loyalty Tokens and updating Headers for future requests
                if (response.ResponseData?.accessToken && response.ResponseData?.refreshToken) {
                    this.authService.setHttpHeadersLoyaltyAccessToken(response.ResponseData.accessToken, response.ResponseData.refreshToken);
                }
                this.dialogRef.close(response);
			},
			(err: any) => {
                console.debug('verify-code-dialog > verifyCode > Error: ', err);
                this.errorHandler(err);
                this.authenticating = false;
			}, 
            () => this.authenticating = false
		);
    }

    resendCode() {
        this.resending = true;
        this.formModel.code = '';

        const args = {
            phone: this.data.phone,
        };

        return this.authService.resendPicode$(args).subscribe(
			() => {
                this.appService.sendLogger({message:'Verify code dialog - Verification Code Re-sent'});
			},
			(err: any) => {
                console.debug('verify-code-dialog > resendCode > Error: ', err);
                this.errorHandler(err);
			}, 
            () => this.resending = false
		);
    }

    errorHandler(err) {
        const errorKey = get(err.error.error, "Key");
        let dialogText = 'error_general';

        if (errorKey === 'CannotCreateNewPinCode') {
            dialogText = 'MESSAGES.TOO_MANY_CODE_REQUESTS'
        } else if (errorKey === 'InvalidPinCode') {
            dialogText = 'MESSAGES.PHONE_VERIFICATION_FAILED';
        }
        
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.appService.mainMessage({
                dialogType: 'error',
                dialogTitle: 'error_title',
                dialogText,
                secondaryButtonHidden: true
            });
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

}
