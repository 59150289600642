export const orderingStrings_ruRU = {
    "TO-ADD": "добавление ",
    "TO-UPDATE": "обновление ",
    "JUST-CHECKING": "Просто проверяем ",
    "ADD": "Добавить ",
    "UPDATE": "Обновить ",
    "ORDER-TOTAL": "Сумма заказа ",
    "CASH": "Наличные ",
    "PAYUP": "Перейти к оплате ",
    "THANK_YOU": "Спасибо!",
    "AMOUNT_TO_PAY": "Сумма к оплате ",
    "TIP": "Чаевые ",
    "PAYED": "оплачено ",
    "CONTINUE": "Продолжение ",
    "TOTAL_IN_TIP": "Итог, включая чаевые ",
    "PARTIAL_PAY_IN_TIP": "Частичная оплата, включая чаевые ",
    "CLEAR": "Очистить ",
    "CLEAR_SELECTION": "Очистить выбор ",
    "APPROVE": "Подтвердить ",
    "PAY": "оплата ",
    "CANCEL": "Отменить ",
    "NO_THANKS": "Нет, спасибо ",
    "REMAINED_PAY": "осталось заплатить ",
    "TABLE_BILL": "Счет за столик ",
    "WAITER": "Официант ",
    ";": "Оплатить ",
    "SAVE_CARD_INFO": "Сохранить данные платежа ",
    "TOTAL": "Итог ",
    "CARD_HOLDER_NAME": "Имя владельца карты ",
    "CARD_NUMBER": "Номер карты ",
    "SIGNIN": "Подключиться ",
    "SIGNIN_INFO": "Введите идентификационные данные ",
    "VALIDATING_CREDENTIALS": "Проверка данных...",
    "WELCOME_BACK": "Добро пожаловать ",
    "BACK_TO_MAIN_SCREEN": "Вернуться на главный экран ",
    "SELECT_EXISTING_CARD": "Выберите существующую карту ",
    "ADD_NEW_CARD": "Добавить новую карту ",
    "SAVE_CARD_AS_DEFAULT": "Сохранить карту по умолчанию ",
    "ORDER_TOTAL": "Сумма заказа",
    "ORDER_TIP": "Чаевые ",
    "PAID": "Оплачено ",
    "PERFORMING_PAYMENT": "Платежная операция...\n  Пожалуйста, не закрывайте страницу",
    "FINALIZING_ORDER": "Работаем над этим...\n  Пожалуйста, не закрывайте страницу",
    "ENTER_ORDER_N": "Введите код заказа ",
    "REQUIERED": "Обязательно для заполнения ",
    "REQUIERED_CHECK": "Обязательно отметить ",
    "PASSWORDS_DONT_MATCH": "Пароли не совпадают ",
    "PASSWORDS_MIN_LENGTH": "Пароль должен содержать не менее 6 знаков ",
    "INVALID_CREDIT_NUMBER": "Номер карты недействителен ",
    "INVALID_CVV": "CVV недействителен ",
    "INVALID_CARD_EXPIRATION": "Дата истечения срока действия недействительна ",
    "INVALID_EMAIL": "Электронная почта не верна ",
    "EMAIL_FOR_INVOCE": "EMAIL Отправить счет-фактуру ",
    "CARD_EXPIRATION": "Дата истечения срока действия ",
    "CARD_HOLDER_CELL": "Номер телефона владельца карты ",
    "CARD_HOLDER_MAIL": "Электронная почта владельца карты ",
    "Q_SHARING_BILL": "Частичная оплата ",
    "Q_TIP_AMOUNT": "Сколько чаевых добавить?",
    "Q_HOW_MUCH_PAY": "Сколько Вы хотите заплатить?",
    "Q_HOW_MUCH_SPLIT": "На сколько человек разделить счет?",
    "Q_REGISTARED": "Вы регистрировались раньше? Соединить ",
    "Q_NEW_REGISTARED": "Новый клиент? Зарегистрироваться ",
    "Q_INVOICE_BY_MAIL": "Хотите получить счет по электронной почте?",
    "Q_REMOVE_CARD": "Удалить карту?",
    "Q_REMOVE_PAYMENT": "Вы уверены, что хотите удалить этот платеж?",
    "SIGNIN_REGISTER": "Зарегистрироваться / Войти ",
    "END": "Закончить ",
    "ENTER_TIP": "Введите чаевые ",
    "ENTER_AMOUNT": "Введите сумму ",
    "SPLIT_AMOUNT": "Разделить сумму ",
    "YOUR_AMOUNT_TO_PAY": "Ваша сумма к оплате??",
    "PRESS_HERE": "Нажмите здесь ",
    "OTHER": "Другая сумма ",
    "SENDING_INVOICE": "Отправка счета...",
    "CHALLENGE_CODE": "Код верификации ",
    "ENTER_CHALLENGE_CODE": "Введите код верификации ",
    "REMEMBER_ME_ON_PHONE": "Запомнить меня на этом устройстве ",
    "BILL_AMOUNT": "Сумма счета ",
    "PAY_WITH_ONOTHER_CARD": "Оплатить другой картой ",
    "PAYMENT_METHOD": "Способы оплаты ",
    "SELECT_PAYMENT_METHOD": "Выбор способа оплаты ",
    "CREDIT_CARD": "Кредитная карта",
    "CREDIT": "Кредитная ",
    "creditCard": "Кредитная карта ",
    "CIBUS": "Сибус ",
    "Cibus": "Сибус ",
    "TENBIS": "Тен Бис ",
    "10bis": "Тен Бис ",
    "Credit": "Кредитная картаי",
    "Ten Bis": "Тен Бис ",
    "cash": "Наличные ",
    "EDIT_ACCOUNT_SETTINGS": "Изменить настройки учетной записи ",
    "MY_ACCOUNT": "Моя учетная запись",
    "SIGN_OUT": "Выйти ",
    "MY_ACCOUNT_DETAILS": "Детали моей учетной записи",
    "EDIT_PAYMENT_INFO": "Изменить способ оплаты ",
    "DELETE_CARD": "Удалить карту ",
    "SET_DEFAULT_CARD": "Установить по умолчанию ",
    "YES_DELETE": "Да, удалить ",
    "OFFER_REMARKS": "Примечания к блюду ",
    "ENTER_OFFER_REMARKS": "Примечания к блюду ",
    "DELETE_PAYMENT": "Удалить платеж ",
    "CONFIRM_CELL": "Confirm Phone",
    "CONFIRM_CELL_MESSAGE": "A confirmation message will be sent to your mobile phone",
    "USE_ENTERED_PHONE": "Send to:",
    "ENTER_ALTERNATE_PHONE": "Use other:",
    "EMAIL_NOT_FOUND": "Адрес электронной почты не найден в системе ",
    "GRATUITY": "Чаевые ",
    "WITHOUT_GRATUITY": "Без чаевых ",
    "ORDER_SCHEDULING": "Когда вам удобно получить заказ?",
    "ORDER_SCHEDULING_TA": "Когда вам удобно получить заказ?",
    "ORDER_SCHEDULING_DELIVERY": "Когда вам удобно получить заказ?",
    "THROTTLING_DISCLAIMER": "Самое раннее доступное время для этого заказа",
    "SELECT_SERVICE_TYPE": "Тип услуги ",
    "CLOSE": "Закрыть ",
    "YES": "Да ",
    "NO": "Нет ",
    "REMOVE": "Удалить ",
    "SEND_ORDER": "Отправить заказ ",
    "TRACK_YOUR_ORDER": "Для отслеживания вашего заказа ",
    "CONTINUE_ORDERING": "Продолжить процесс заказа ",
    "ADD_ITEM": "Добавить товар ",
    "SELECT_ITEM": "Выбрать товар ",
    "UPDATE_ITEM": "Обновить товар ",
    "TOPPINGS": "Добавки к блюду ",
    "DECISIONS": "Решения ",
    "ITEM_CHANGES": "Изменения в блюде ",
    "SELECTION_GROUP_SELECT": "Выберите {{count}} товар / товара /товаров)",
    "SELECTION_GROUP_SELECT_SINGLE": "( Выберите один товар)",
    "SELECTION_GROUP_SELECT_MIN": "( Выберите не менее {{count}} товаров)",
    "SELECTION_GROUP_SELECT_SINGLE_MIN": "( Выберите хотя бы один товар)",
    "GROUP_MOD_SELECT_ERROR": "Выберите не менее {{count}} добавок из: {{group}}",
    "GROUP_MOD_SELECT_SINGLE_ERROR": "Выберите хотя бы один из: {{group}}",
    "GROUP_SELECT_ERROR": "Выберите не менее {{count}} товаров из: {{group}}",
    "GROUP_SELECT_SINGLE_ERROR": "Выберите хотя бы один товар из: {{group}}",
    "SELECTION_GROUP_OPTIONAL_MAX": "При желании выберите до {{count}} товаров",
    "SELECTION_GROUP_OPTIONAL_MAX_SINGLE": "Опция, выберите еще один товар ",
    "SELECTION_GROUP_DIALOG_ERROR": "Произошла ошибка при загрузке товара, попробуйте еще раз или свяжитесь с рестораном",
    "CHECKOUT": "Перейти к оплате ",
    "Q_REMOVE_OFFER_FROM_BASKET": "Вы уверены, что хотите удалить товар?",
    "ENTER_DELIVERY_ADDRESS": "адрес доставки ",
    "ENTER_DELIVERY_ADDRESS_ALT": "Привет, просто проверяю, доберутся ли они до тебя …",
    "SELECT_ADDRESS": "Выберите адрес ",
    "DELETE_ADDRESS_VALIDATE": "Удалить адрес?",
    "BRANCH": "Ресторан ",
    "ADDRESS": "Адрес ",
    "PHONE": "Телефон ",
    "ENTRANCE": "Вход ",
    "FLOOR": "Этаж ",
    "apartment": "Квартира ",
    "ENTER_STREET_INCLUDING_NUMBER": "Пожалуйста, введите полный адрес ",
    "MISSING_STREET_NUMBER": "Не указан номер дома ",
    "START_ORDER": "Перейти к заказу ",
    "SELECT_CITY": "Выберите город ",
    "SELECT_DELIVERY_CITY": "Выберите город для доставки ",
    "SELECT_BRANCH": "Выберите ресторан ",
    "ORDER_MENU": "Меню ",
    "takeaway": "Самовывоз ",
    "eatin": "Закажи и съешь ",
    "delivery": "Доставка ",
    "seated": "Сидя в ресторане ",
    "RESET_ORDER": "Сделать заказ заново ",
    "ORDER_FROM": "Заказать в филиале ",
    "ORDER_TO": "Доставка по адресу ",
    "CONTACT_INFO": "Личная информация ",
    "CONTACT_ALCOHOL_DECLARATION": "Я старше {{age}} и при необходимости предоставляю удостоверение личности для покупки алкоголя",
    "PAYMENT_INFO": "Данные кредитной карты ",
    "FIRST_NAME": "Имя",
    "LAST_NAME": "Фамилия ",
    "CELL": "Сотовый ",
    "EMAIL": "Email",
    "REVIEW_YOUR_ORDER": "Подробности заказа ",
    "ADD_ITEMS": "Добавить товары ",
    "UPDATE_ITEMS": "Обновить товары ",
    "ORDER_SENT_SUCCESS": "Заказ успешно отправлен ",
    "ORDER_NEED_ATTENDANCE": "Ошибка при  отправке заказа ",
    "CALL_RESTAURANT_TO_COMPLETE_ORDER": "Пожалуйста, позвоните в ресторан ",
    "ORDER_NUMBER": "Номер заказа ",
    "START_NEW_ORDER": "Начать новый заказ ",
    "ORDER_ITEMS": "Детали заказа ",
    "ORDER_WILL_BE_SENT_TO": "Заказ будет отправлен по адресу ",
    "ESTIMATED_DELIVERY_TIME": "Время прибытия примерно ",
    "ESTIMATED_PREPARATION_TIME": "Время подготовки примерно ",
    "ESTIMATED_SUPPLY_TIME": "Срок поставки до",
    "MINUTES": "Минуты  ",
    "MINUTES_SHORT": "Мин",
    "PREPARATION_TIME": "Время подготовки ",
    "ORDER_TYPE": "Тип заказа ",
    "PLEASE_SELECT": "Пожалуйста, выберите ",
    "PLACEMENT": "Варианты ",
    "NUMBER_OF_PEOPLE": "Количество человек ",
    "READ_AND_APPROVE_CONTRACT": "Я прочитал и согласен ",
    "READ_AND_APPROVE_CONTRACT_C": "К условиям использования",
    "READ_AND_APPROVE_CONTRACT_D": "С Политикой конфиденциальности ",
    "READ_CONTRACT": "Прочтите Условия использования...",
    "PLEASE_APPROVE_CONTRACT": "Пожалуйста, подтвердите  Условия использования ",
    "USAGE_CONRACT": "Условия использования ",
    "PRIVACY_CONRACT": "Политика конфиденциальности ",
    "ADDRESS_REMARKS": "Указания для курьера ",
    "LOADING_MENU": "Загрузка меню.....",
    "MOD_WITH": "Добавки ",
    "MOD_WITHOUT": "Без ",
    "PASSWORD": "Пароль ",
    "CONFIRM_PASSWORD": "Введите пароль еще раз ",
    "FORGOT_PASSWORD": "Забыли пароль...",
    "RESTORE_PASSWORD": "Восстановить пароль ",
    "INCLUDE_SILVERWARE": "Добавьте одноразовые столовые приборы ",
    "CURBSIDE_PICKUP": "Заказать из машины ",
    "CURBSIDE_PICKUP_DESC": "Пожалуйста, опишите модель автомобиля, цвет и номер лицензии.",
    "AUTHENTICATING": "Проверьте детали ",
    "SIGNED_IN_AS": "Подключиться по имени ",
    "PAY_WITH": "Оплатить с помощью ",
    "PAY_WITH_SAVED_PMS": "Сохраненные способы оплаты ",
    "SELECT_ALTERNATE_PYMENT": "Выберите другой способ оплаты ",
    "SIGHN_IN_EMAIL": "Электронная почта для идентификации ",
    "SIGHN_IN_PASSWORD": "Пароль для идентификации ",
    "UPDATE_PAYMENT_INFO": "Обновить способ оплаты ",
    "SAVE_ACCOUNT_PAYMENT_INFO": "Сохранение способа оплаты в Вашей учетной записи",
    "UPDATE_ACCOUNT": "Обновить учетную запись ",
    "ADD_PAYMENT_INFO": "Добавить способ оплаты ",
    "UPDATING_ACCOUNT": "Обновление Вашей учетной записи...",
    "UPDATE_SIGNIN_INFO": "Обновить идентификационную информацию ",
    "PASS_MIN_LENGTH_ERROR": "Пароль должен содержать не менее 6 символов ",
    "OLD_PASSWORD": "Старый пароль ",
    "NEW_PASSWORD": "Новый пароль ",
    "BRANCH_TIME_0": "Часы работы сервиса {{t0}}",
    "BRANCH_TIME_1": "Часы работы c {{t0}} до {{t1}}",
    "BRANCH_TIME_CONFIRM_0": "В настоящее время мы закрыты. Мы вернемся к работе {{t0}}",
    "BRANCH_TIME_CONFIRM_1": "В настоящее время мы закрыты. Мы работаем с {{t0}} до {{t1}}",
    "BRANCH_TIME_CONFIRM_0_AFTER_COMPLETED": "Услуга больше не доступна. Мы работаем с {{t0}} ",
    "BRANCH_TIME_CONFIRM_1_AFTER_COMPLETED": "Услуга больше не доступна. Мы работаем с {{t0}} до {{t1}}",
    "CONFIRM": "Подтвердить ",
    "ORDER_SUCCESS": "Подтвердить заказ ",
    "MIN-ORDER-PRICE": "Минимальная сумма заказа ",
    "DELIVERY-PRICE": "Стоимость доставки ",
    "TOTAL-ITEMS": "Всего товаров ",
    "TOTAL-TO-PAY": "Всего к оплате ",
    "WITHOUT": "без ",
    "CONTINUE_TO_PAYMENTINFO": "Перейти к оплате ",
    "PAYMENT": "Оплата ",
    "BRANCHES": "Филиалы ",
    "SERVER_ERROR": "Ошибка сервера ",
    "ERROR": "Ошибка ",
    "PAYMENT_ERROR": "Ошибка при оплате ",
    "PARTIAL_PAYMENT_ERROR": "Ошибка при оплате ",
    "NAME": "Имя ",
    "REGISTERING": "Зарегистрируйтесь ",
    "REGISTER": "Зарегистрироваться ",
    "RESET_PASSWORD": "Переустановка пароля ",
    "RESET_PASSWORD_INFO": "Введите новый пароль и нажмите «Сброс». ",
    "ORDER_HISTORY": "История заказов ",
    "OFFERS_HISTORY": "Вы заказали раньше ",
    "SELECT_ORDER_METHOD": "Выберите услугу",
    "ORDER_METHOD": "Форма заказа ",
    "ORDER_FIXED": "Счет ",
    "SET_QUANTITY": "Введите количество ",
    "CELL_PATTERN": "Номер мобильного должен начинаться с 05",
    "CELL_MINLENGTH": "Номер мобильного должен состоять из 10 цифр ",
    "VIEW_MENU": "Просмотр меню ",
    "ORDER_SERVICE_CLOSED": "Прием заказов закрыт ",
    "ESTIMATED_TIME": "Предполагаемое время ",
    "SET_ORDER_DELAY": "Когда вам удобно получить заказ??",
    "DELAY_TO": "Задержка на час ",
    "SET_ORDER_DELAY_TO_HOUR": "Задержка в выполнении заказа на час ",
    "ASAP": "как можно скорее ",
    "ENTER_CVV": "CVV",
    "ENTER_CVV_MESSAGE": "Введите CVV карты: {{card}}",
    "ENTER_IDCARD": "Удостоверение личности ",
    "ENTER_IDCARD_MESSAGE": "Введите идентификатор карты: {{card}}",
    "PRMARY_PAYMENT_METHOD": "По умолчанию ",
    "ADD_PAYMENT": "Добавить платеж ",
    "SPLIT_PAYMENT": "Разделить платеж ",
    "WALLET_PAYMENT": "кошелек, {{type}}",
    "SPLIT_PAYMENTS_DIFF_ERROR": "Сумма платежей не равна {{total}}, разница составляет {{diff}}",
    "BACK_TO_SITE": "Вернуться на сайт ",
    "BACK_TO_APP": "Вернуться в приложение ",
    "FREE_DELIVERY_FROM": "Бесплатная доставка от ",
    "ADD_REMAINNING_AMOUNT": "Полная оплата суммы заказа ",
    "PAYMENTS_EXCEED_TOTAL_BY": "Сумма платежей превышает сумму заказа на ",
    "PAYMENT_AMOUNT": "Сумма платежа ",
    "ENTER_FIRST_PAYMENT_AMOUNT": "Введите сумму первого платежа ",
    "EXCESS_PAYMENT": "Баланс оплаты ",
    "CLICK_TO_REMOVE_EXCESS_AMOUNT": "Нажмите, чтобы загрузить лишнюю сумму ",
    "CANCEL_SPLIT_PAYMENT": "Отменить разделение платежей ",
    "APPLY_PAYMENTS": "Подтвердите платежи ",
    "SINGLE_PAYER": "Единственный плательщик ",
    "MULTIPLE_PAYERS": "Несколько плательщиков ",
    "SPLIT_ORDER_BETWEEN_PAYERS": "Разделить между несколькими плательщиками ",
    "CALL_RESTAURANT": "Позвонить в ресторан ",
    "SELECT_TABLE": "Куда подать еду? ",
    "TABLE_NO": "Номер столика ",
    "TABLE_NO_ORDER": "Номер столика {{no}}",
    "ENTER_ORDER_NO": "Введите номер заказа ",
    "ADD_GRATUITY_TO_ORDER": "Чаевые ",
    "ADD_GRATUITY_TO_COURIER": "Хотите добавить чаевые?",
    "ORDER_TOTAL_WITHOUT_GRATUITY": "Общая сумма к оплате без чаевых ",
    "ORDER_TOTAL_WITH_GRATUITY": "Общая сумма платежа, включая чаевые: {{total}}",
    "GRATUITY_AMOUNT": "Сумма чаевых ",
    "GRATUITY_PERCENT": "Процент чаевых ",
    "ENTER_GRATUITY_AMOUNT": "Введите сумму чаевых ",
    "CONTINUE_TO_GRATUITY": "Перейти к чаевым ",
    "REFRESH_ORDER": "Обновить заказ ",
    "MONTH": "Месяц ",
    "YEAR": "Год ",
    "ENTER_AMOUNT_TO_PAY_NOT_INCLUDING_GRATUITY": "Введите сумму к оплате, не считая чаевых.",
    "ENTER_AMOUNT_TO_PAY": "Введите сумму к оплате ",
    "FULL_PAYMENT": "Полная оплата ",
    "PARTIAL_PAYMENT": "Частичная оплата ",
    "AMOUNT_EXEEDS_BALANCE_DUE": "Сумма превышает баланс платежа ",
    "MAX_TO_USE": "У вас есть {{amount}} {{type}} для использования ",
    "EXCEEDED_CASH_AMOUNT": "Максимальная сумма для оплаты наличными составляет {{amount}} шекелей",
    "POINTS": "баллы ",
    "CANNOT_PAY_TIP": "При этом способе оплаты невозможно оставлять чаевые. ",
    "MY_WALLET": "мой кошелек:",
    "OTHER_TENDERTYPES": "Другой:",
    "NEW_PAYMENT": "Новый платеж ",
    "REMAINDER_NOT_EXISTS": "Остатка для оплаты не осталось, необходимо изменить суммы платежей",
    "BACK": "Вернуться ",
    "PASS_ORDER": "Разделить счет?",
    "PASS_ORDER_TITLE": "Переслать другу ",
    "PASS_ORDER_BY_PHONE_MESSAGE": "Чтобы отправить ссылку в текстовом сообщении, введите номер телефона.",
    "PASS_ORDER_BY_PHONE_CAPTION": "Номер мобильного телефона ",
    "PASS_ORDER_BY_PHONE_SEND": "Отправить заказ ",
    "PASS_ORDER_BY_PHONE_QR_MESSAGE": "или отсканируйте штрих-код ",
    "PASS_ORDER_SENT_SUCCESS": "Заказ успешно отправлен ",
    "CANNOT_SEND_ORDER_BY_SMS": "Приносим извинения, но в данный момент ссылку отправить невозможно..",
    "WORK_HOURS": "Часы работы ",
    "RESTAURANT_WEBSITE": "Сайт ресторана ",
    "SELECT_ONE": "Выберите один ",
    "DELIVERY_ADDRESS": "Адрес доставки ",
    "DELIVERY_DETAILS": "Подробности доставки ",
    "ADDRESS_DETAILS": "Подробности адреса ",
    "BRANCH_DETAILS": "Подробности о ресторане ",
    "ADDITIONAL_OPTIONS": "Дополнительные варианты ",
    "MINUTES_VAL": "{{m}} минут ",
    "ADDITIONAL_OFFERS": "Добавки и соусы ",
    "SELECT_ADDITIONAL_OFFERS": "Выбирайте добавки и соусы ",
    "CONTINUE_TO_CONTACTINFO": "Перейти к контактным  данным ",
    "CC_CUSTOMER_NAME": "Имя ",
    "CC_CUSTOMER_ID": "Удостоверение личности / регистрационный номер ",
    "ID_CARD": "Удостоверение личности ",
    "ORDERER_NAME": "Имя заказчика ",
    "ORDERER_PHONE": "Телефон ",
    "ORDER_NO": "Номер заказа ",
    "ORDER_TAGS": {
        "BREAKFAST": "Завтрак ",
        "LUNCH": "Обед ",
        "DINNER": "Ужин ",
        "WORK": "Работа "
    },
    "ENTER_LOCALITY": "Введите название города/поселка ",
    "LOCALITY_TITLE": "Название города/поселка ",
    "STREET_TITLE": "Название улицы ",
    "HOUSE_TITLE": "Номер дома ",
    "TITLE": "Поставки {{name}} – TabitOrder",
    "TITLE_1": "{{name}} Поставки ",
    "TITLE_2": "{{name}} - TabitOrder",
    "PAYMENT_IN_PROGRESS": "Производим платеж...\  Пожалуйста, не закрывайте окно",
    "CREATING_ORDER": "Отсылаем транзакцию...",
    "LOADING_ORDER": "Загрузка транзакции...",
    "INVALID_AMOUNT": "Сумма к оплате указана неверно ",
    "INVALID_AMOUNT_RANGE": "Действительная сумма от {{min}} до {{max}}",
    "INVALID_AMOUNT_MIN": "Минимальная сумма {{min}}",
    "INVALID_AMOUNT_MAX": "Максимальная сумма {{max}}",
    "BACK_TO_MENU": "Вернуться в меню ",
    "ORDER_NOTES": "Примечания к заказу ",
    "Q_SAVEPM_1": "Заинтересованы в сокращении времени заказа?",
    "Q_SAVEPM_2": "ניתן לשמור את אמצעי התשלום בארנק הדיגיטלי שלנו, לתשלומים קלים ומהירים בעתיד",
    "Q_SAVEPM_3": "תשלום ושמירה",
    "Q_SAVEPM_4": "תשלום ללא שמירה",
    "DISH_CALCULATOR": "Калькулятор порций ",
    "MAKE_ADDITIONAL_PAYMENT": "Внесите дополнительную оплату ",
    "MAKE_ADDITIONAL_PAYMENT_ONDEVICE": "Дополнительный платеж с этим устройством ",
    "DINER": "гость ",
    "GENERAL_DINER": "Центр стола ",
    "ADDRESS_WITHOUT_HOUSE": "Адрес без номера дома ",
    "TAX_AMOUNT": "Налоги ",
    "ADDRESS_TYPE": "Тип адреса ",
    "VALID_UNTILL": "Годен до ",
    "COMMENT": "Примечания ",
    "LEAVE_ORDER_OUTSIDE": "Оставьте доставку за дверью ",
    "LEAVE_ORDER_OUTSIDE_CASH": "Так что давайте держаться на расстоянии 😊 - будут возможны только цифровые платежи",
    "LEAVE_ORDER_OUTSIDE_COMMENT": "Оставьте за дверью ",
    "arr_join": "ו",
    "SIGN_OUT_MESSAGE": {
        "TITLE": "За секунду до того, как мы расстанемся ",
        "TEXT": "Просто убедитесь, что вы действительно хотите отключиться ",
        "PRIMARY_BUTTON": "Да ",
        "SECONDARY_BUTTON": "Нет, я хочу продолжить "
    },
    "MESSAGES": {
        "PLACE-MAP-MARKER": "Пожалуйста, разместите карту как можно ближе к адресу.",
        "MAX_ORDER_OFFERS_CONT": "В корзине больше нет места...\ Нельзя заказать более {{max}} товаров в одном заказе",
        "MAX_CAT_OFFERS_CONT": "Вы не можете заказать более {{max}} позиций из меню {{target}}",
        "MAX_SAME_DISH_COUNT": "Нельзя заказать более {{max}} {{target}}",
        "SIGNIN-EMAIL-EX": "Введите адрес электронной почты, с которым Вы ранее регистрировались ",
        "SIGNIN-EMAIL-EX-HELP": "Код подтверждения будет отправлен на мобильный телефон, связанный с адресом электронной почты ",
        "SIGNIN-CHALLENGE-EX": "Код подтверждения отправляется на телефон ",
        "SIGNIN-CHALLENGE-EX-HELP": "Не получили код? Отправили еще раз",
        "SAVE_INFO": "Сохраните контактные и платежные данные שמור פרטי קשר ותשלום",
        "SAVE_INFO_END": "Зарегистрируйтесь , чтобы в будущем Вам было просто делать заказы ",
        "SAVE_INFO_PAYMENT": "Сохранить данные платежа ",
        "SIGNIN_CHALLENGE": "Вам был отправлен ответный код по SMS, введите его и нажмите «Подключиться",
        "PAYMENT_CHALLENGE": "Код подтверждения будет отправлен на\ {{phone}}\ по SMS",
        "PAYMENT_ENTER_PHONE": "Просто быстрая проверка телефона и мы продолжим ",
        "PAYMENT_OTP": "отправили вам код подтверждения на\n {{phone}}",
        "ROOM_CHARGE_CHALLENGE": "Код подтверждения отправляется на номер, заканчивающийся на {{number}}",
        "TRANSACTION_SUCCESS": "Платеж успешно произведен ",
        "ORDER_CLOSED": "Заказ оплачен полностью ",
        "INVALID_ORDER": "Заказ не найден ",
        "PAYMENT_FAILED": "Платеж не прошел ",
        "INVALID_PIN": "Введённый код не совпадает ",
        "SIGN_FOR_PAYMENT_CONFIRM": "Для подтверждения оплаты распишитесь здесь ",
        "SIGNATURE_MANDATORY": "Для того, чтобы мы подтвердили способ оплаты, Вам необходимо расписаться!",
        "WAITER_NOTIFIED": "Наша команда получила ваше обращение и скоро свяжется с вами!",
        "HAPPY": "Мы хотели бы видеть Вас снова ",
        "INVOICE_SENT": "Счет был отправлен вам на электронную почту ",
        "ITEM_ADDED_TO_BASKET": "Товар добавлен в корзину ",
        "NO_BASKET_ITEMS": "Корзина пуста ",
        "CONFIRM_ORDER_FROM_BRANCH": "Вы выбрали заказ в филиале: {{branch}}",
        "FINDING_THE_BRANCH_NEAREST_TO_YOU": "Ближайший к вашему дому филиал ",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS": "Не найдено ни одного филиала, обслуживающего адрес: {{address}}",
        "NO_BRANCH_SERVING_ADDRESS": "Ресторан не осуществляет доставку по этому адресу.",
        "NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "В настоящее время мы отправляем только мгновенные заказы на этот адрес",
        "CONFIRM_RESET_ORDER": "Вы уверены, что хотите снова начать заказ?",
        "DO_YOU_APPROVE_CONTRACT": "Подтвердить Условия использования?»",
        "DO_YOU_APPROVE_CONTRACT_TITLE": "Продолжим через секунду ",
        "CONDITIONS_PRIMARY_BUTTON_TEXT": "Да ",
        "CONDITIONS_SECONDARY_BUTTON_TEXT": "Выход ",
        "RESTORE_PASSWORD_1": "Пожалуйста, введите адрес электронной почты, который Вы использовали для идентификации.",
        "SIGNIN_SUCCESS": "С возвращением {{name}}, вы успешно подключились к системе",
        "FORGOTPASS_SUCCESS": "Инструкции по входу были отправлены на указанный вами адрес электронной почты.",
        "ACCOUNT_UPDATE_SUCCESS": "Ваша учетная запись успешно обновлена!",
        "TITLE_HUNGRY": "Извините ",
        "BRANCH_DISABLED_NOW": "Сервис сейчас закрыт. {{t}}.",
        "BRANCH_DISABLED_NOW_ERROR": "Но этот сервис закрылся. Он доступен с {{t}}.",
        "BRANCH_DISABLED_NOW_DELAYED_ENABLED": "Сейчас сервис закрыт. Вы можете сделать заказ на другой день.",
        "BRANCH_CLOSED_TODAY": "Эта услуга сегодня не активна ",
        "BRANCH_CLOSED_NOW": "Сервис откроется в {{t}}, но не волнуйтесь,\Вы можете сделать предварительный заказ, который будет подготовлен к моменту открытия",
        "MIN-ORDER-PRICE-TITLE": "Almost there",
        "MIN-ORDER-PRICE-BUTTON": "Back to Menu",
        "MIN-ORDER-PRICE": "You're {{t}} away from the minimum order amount.\nPlease add more items.",
        "SERVER_ERROR": "При обработке запроса произошла ошибка.\nПожалуйста, свяжитесь с рестораном",
        "PAYMENT_ERROR": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов.",
        "PAYMENT_ERROR_TITLE": "Ошибка оплаты ",
        "REGISTER_SUCCESS": "Вы успешно зарегистрировались ",
        "PASSWORD_RESET_SUCCESS": "Пароль успешно изменен ",
        "CUSTOMER_ENTITYALREADYEXISTS": "Клиент с данным адресом электронной почты уже существует в системе. ",
        "PHONE_VERIFICATION_FAILED": "Кажется, код неправильный, попробуйте еще раз.",
        "PHONE_VERIFICATION_FAILED_TITLE": "минуточку...",
        "TOO_MANY_CODE_REQUESTS": "Ваша учетная запись временно заблокирована, поскольку вы достигли максимально разрешенного количества попыток.\nПовторите попытку позже.",
        "VOUCHER_ALREADY_USED": "Похоже, что ваучер уже погашен",
        "SERVICE_UNAVAILABLE": "Эта услуга в настоящее время недоступна, повторите попытку позже. ",
        "SERVICE_UNAVAILABLE_THROTTLING": "В настоящее время мы загружены на полную мощность. Пожалуйста, перезапустите ваш заказ с другим временным интервалом.",
        "SERVICE_UNAVAILABLE THROTTLING With_PHONE": "В настоящее время мы загружены на полную мощность.\Пожалуйста, позвоните в ресторан ({{phone}})",
        "CLP_PAYMENT_CANCELLED_TITLE": "Payment Canceled",
        "CLP_PAYMENT_CANCELLED": "We don't currently accept this type of card. Please try again with another card.",
        "IG_CANT_SELECT_MORE_THEN": "Вы должны выбрать не более {{max}} товаров!",
        "MOD_CANT_SELECT_MORE_THEN": "Вы должны выбрать не более {{max}} добавок!",
        "MOD_PLEASE_SELECT_AT_LEAST": "Необходимо выбрать не менее {{min}} добавок!",
        "MOD_PLEASE_SELECT_AT_LEAST_ONE": "Вы должны выбрать хотя бы одну добавку!",
        "CANNOT_PAY_TRAINING_MODE": "Система в режиме проверки. Транзакция не может быть выполнена. Пожалуйста, позвоните в ресторан\n {{phone}}",
        "ARE_YOU_SURE": "Вы уверены?",
        "DISABLED_REGION_TITLE": "Служба доставки не доступна ",
        "DISABLED_REGION_MESSAGE": "В настоящее время мы не можем связаться с этим адресом.\nПожалуйста, повторите попытку позже.",
        "DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE": "В настоящее время мы отправляем только срочные заказы на этот адрес",
        "DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE": "В настоящее время мы отправляем на этот адрес только будущие заказы ».",
        "ENABLED_REGION_TIME_MESSAGE": "Мы вернемся к работе в {{t}}",
        "ENABLED_REGION_DAY_MESSAGE": "Мы вернемся к работе {{d}} в{{t}}",
        "OUT_OF_REACH": "Извините за неудобство ",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILED": "Один или несколько введенных Вами платежей не удалось провести. Позвоните в ресторан, чтобы завершить заказ.\n {{phone}}",
        "SOME_OF_MULTIPLE_PAYMENTS_FAILED_LOCALLY": "Один или несколько введенных вами платежей не удалось провести. Пожалуйста, свяжитесь с официантом, чтобы продолжить.",
        "ORDER_CHANGED_AFTER_HANDOFF": "Внимание!\ Заказ уже отправлен в ресторан, новые изменения не сохранены.\ Для внесения изменений необходимо позвонить в ресторан: {{phone}}",
        "SELECTTABLE_INFO": "Пожалуйста, введите номер стола, который Вы хотите зарезервировать ",
        "ENTERORDER_INFO": "Пожалуйста, введите номер заказа, за который Вы хотите заплатить.",
        "INVALID_TABLE": "Стол не существует ",
        "ORDER_RELOAD_NEEDED": "Пожалуйста, обратите внимание! Сумма к оплате изменилась!",
        "ORDER_HELD_BY_SERVER": "Приносим свои извинения, но оплата в настоящее время невозможна. Пожалуйста, повторите попытку через несколько минут",
        "SESSION_VALIDATION_FAILED": "При обработке запроса произошла ошибка, обновите страницу и повторите попытку».",
        "PAY_ORDER_CHANGED": "Заказ изменился! Пожалуйста, проверьте  заказ.",
        "PAYMENT_ACCEPTED_SUCCESSFULLY": "Платеж прошел успешно ",
        "PAYMENT_ACCEPTED": "Платеж прошел успешно ",
        "ITEM_OUTOF_STOCK": "Выбранного вами блюда на данный момент нет в наличии, но есть другие  варианты!",
        "ITEM_OUTOF_STOCK_TITLE": "Ой, закончилось ",
        "ITEM_OUTOF_STOCK_BUTTON": "Вернуться в меню ",
        "OUT_OF_STOCK": "На момент заказа нет в наличии следующих блюд:\n {{items}}\n Мы будем рады предложить Вам другие варианты из меню",
        "GRATUITY_CASH_DISABLE": "При наличии чаевых оплата наличными невозможна. ",
        "ENABLE_GRATUITY_CREDIT_ONLY": "Вы можете оплатить кредитной картой только при наличии чаевых. ",
        "ORDER_ITEMS_UNAVALABLE": "К сожалению, на данный момент для заказа недоступны следующие позиции:",
        "ORDER_TIMEOUT": "Срок действия заказа истек! Необходимо заказать заново ",
        "CONFIRM_ADDRESS": "Введен адрес:\n<b>{{address}}</b>\n Продолжить?",
        "CONFIRM_ADDRESS_SITE": "Посылка прибудет из филиала: {{a}}\n по адресу: {{b}}",
        "PROBLEM_IN_PAYMENT_INPUT": "Произошла ошибка при получении платежных данных.\nПожалуйста, введите данные платежа еще раз.\nЕсли проблема не исчезнет, свяжитесь с рестораном по телефону: \n {{phone}}",
        "MEAL_CALC_FACTOR_DESC": "Скидка рассчитывается на каждый товар в зависимости от его относительного веса в заказе.",
        "MEAL_CALC_ACTION_DESC": "Блюда должны быть отмечены для оплаты ",
        "MEAL_CALC_TOTAL_DESC": "Общая сумма стоимости блюд не может превышать сумму, оставшуюся к оплате.",
        "EX_PAYMENT_ERROR": "Произошла ошибка при ссылке на платежную систему",
        "EX_PAYMENT_AUTHRIZATION_ERROR": "Произошла ошибка при подтверждении оплаты от поставщика. ",
        "LOCALITY_WO_STREETS": "В населенном пункте {{locality}} названия улиц не определены\Хотите выбрать этот населенный пункт в качестве пункта назначения доставки?",
        "SERVICE_END_WARN": "Внимание! Служба бронирования закроется через {{t}} минут. Заказ должен быть выполнен за оставшееся время",
        "JUST_SO_YOU_KNOW": "Нам важно проинформировать вас ",
        "COMPLETE_ORDER_IN_TIME_PRIMARY_BUTTON_TEXT": "Подтвердить ",
        "SUMMARY_SENT_BY_MAIL": "Сводная информация о заказе отправлена на ваш адрес электронной почты. ",
        "CONTINUE_WITHOUT_GRATUITY": "Продолжить без чаевых?",
        "LOYALTY_BENEFIT_TIMEOUT_ERROR": "Привет, прошло много времени...\ придется использовать ваши бонусы",
        "LOYALTY_BENEFIT_HANDOFF_ERROR": "Этот заказ невозможно оплатить картой, оканчивающейся на цифры {{num}}.\ Для получения дополнительной информации свяжитесь с рестораном",
        "LOYALTY_BENEFIT_CONFLICT_ERROR": "К сожалению...\ Похоже, возникла проблема или бонус уже использован.\ Пожалуйста, повторите попытку",
        "CARD_VALIDATION_MISMATCH": "Несоответствие при валидации карты ",
        "CARD_VALIDATION_ACTION_NEEDED": "Требуются дополнительные действия ",
        "ORDER_ITEMS_VALIDATION_ERROR": "Невозможно заказать выбранные позиции.\ Пожалуйста, свяжитесь с рестораном, чтобы продолжить заказ",
        "SIGNIN_SISABLED": "Вход/Регистрация временно отключена. Вы по-прежнему можете размещать заказы, как обычно, указав свою контактную информацию.",
        "GRATUITY_NOT_ALLOWED_FOR_PM": "В этом ресторане невозможно добавить чаевые в {{pm}}.\ Вы должны выбрать другой способ оплаты или отменить добавление чаевых.",
    },
    "EX_PAYMENT": {
        "dialog_title": "Подтверждение транзакции ",
        "description": "Данная страница используется для подтверждения транзакции по оплате на сайте. Платеж фактически будет произведен только после завершения процесса на странице компании. Сначала Вы должны подтвердить, что все данные на этой странице верны, и внести необходимые данные кредитной карты",
        "popup-title": "Транзакция одобрена к выполнению ",
        "form-title": "Данные кредитной карты ",
        "popup-description": "Теперь перейдем на страницу организации",
        "submit-btn": "Продолжить ",
        "clear": "Очистить ",
        "credit_card_number": "Номер карты ",
        "expiration": "Дата истечения срока действия карты ",
        "cancel": "отменить ",
        "currency": "валюта:",
        "transaction_details": "Детали транзакции ",
        "total_amount": "сумма ",
        "month": "Месяц ",
        "year": "Год ",
        "click_here": "Нажмите здесь для перевода "
    },
    "SERVER_MESSAGES": {
        "OrderLockedByAnotherUser": "Платеж не прошел. Обслуживается другой пользователь ",
        "INVALID_USER_ORPASS": "Имя пользователя или пароль неверны ",
        "UPDATE_ACCOUNT_ERROR": "Нам не удалось обновить вашу учетную запись ",
        "EMAIL_NOT_FOUND": "Электронная почта в системе не найдена ",
        "EMAIL_IN_USE": "Электронная почта существует в системе! Выберите другой адрес электронной почты ",
        "INVALID_ORDER_ID": "Кажется, заказ уже закрыт ",
        "FIREBASE_DOWN": "Услуга недоступна ",
        "SHVA_OFFLINE": "Кажется, в данный момент возникла проблема с расходами по кредитой карте.\ Мы рекомендуем оплатить другим способом, или повторите попытку через несколько минут",
        "PAYMENT_QUOTA_EXCEEDED": "На карте недостаточно баланса ({{v}})",
        "ROOM_CHARGE_PAYMENT_QUOTA_EXCEEDED": "The amount is higher than the credit limit set for the room. Please go to the reception or pay with another payment method.",
        "SMS_PHONE_ERROR": "Невозможно отправить текстовое сообщение на введенный номер {{phone}}.\ Необходимо связаться с рестораном",
        "CREDIT_GUARD_EX_ERROR": "При обработке запроса произошла ошибка, попробуйте еще раз..",
    },
    "CREDIT_GUARD": {
        "ERROR_CODE_001": "Карта заблокирована, обратитесь в кредитную компанию (код 001).",
        "ERROR_CODE_002": "Карта украдена, обратитесь в кредитную компанию (код 002).",
        "ERROR_CODE_003": "Произошла ошибка, обратитесь в кредитную компанию (код 003).",
        "ERROR_CODE_004": "От кредитной компании получен отказ, сделка не одобрена (код 004).",
        "ERROR_CODE_006": "Удостоверение личности или три цифры на обратной стороне карты неверны, попробуйте еще раз (код 006)",
        "ERROR_CODE_010": "Транзакция не одобрена к исполнению, требуется усиленная проверка. Вам следует обратиться в кредитную компанию (код 010).",
        "ERROR_CODE_033": "Введенный номер карты неверен, попробуйте еще раз (код 033).",
        "ERROR_CODE_036": "Карта недействительна (код 036).",
        "ERROR_CODE_039": "Введенный номер карты неверен, попробуйте еще раз (код 039).",
        "ERROR_CODE_173": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 173)».",
        "ERROR_CODE_200": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 200)».",
        "ERROR_CODE_316": "Внешний интерфейс вышел из строя. Свяжитесь с кредитной компанией (код 316).",
        "ERROR_CODE_358": "Конфиденциальные данные не найдены в базе данных (код 358).",
        "ERROR_CODE_401": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 401)».",
        "ERROR_CODE_405": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 405)».",
        "ERROR_CODE_414": "Нам не удалось перевести платеж, рекомендуем убедиться в правильности всех реквизитов (код 414)».",
        "ERROR_CODE_520": "Авторизации на проведение операции нет, необходимо обратиться в кредитную компанию (код 520).",
        "ERROR_CODE_599": "Сервис токенов эмитента кредита недоступен, необходимо обратиться в кредитную компанию (код 599).",
        "ERROR_CODE_695": "Этот тип кредитной карты не поддерживается, необходимо оплатить другой картой (код 695).",
        "ERROR_CODE_900": "Транзакция была заблокирована из-за невыполнения обязательств по безопасной транзакции (код 900)».",
    },
    "_LEUMIPAY": {
        "payment_error": "Платеж через PAY не выполнен!\nПожалуйста, повторите попытку",
        "min_order_amount_error": "Для оплаты через PAY сумма заказа должна превышать 50 шекелей»",
        "contrat": "К положению о PAY..."
    },
    "SLIP": {
        "OTH": "OTH",
        "ITEM_DISCOUNT": "Скидка на товар ",
        "TICKET_DISCOUNT": "Скидка на счет ",
        "TICKET_OTH": "все за наш счет ",
        "CHANGE": "чаевые/сдача "
    },
    "GROUP_VALIDATION": {
        "SELECT_ONE": "Выберите один товар ",
        "SELECT_SOME": "выберите {{min}} товаров",
        "SELECT_ATLEAST_ONE": "Выберите хотя бы один товар ",
        "SELECT_ATLEAST_SOME": "Выберите не менее {{min}} товаров",
        "SELECT_UPTO_ONE": "Выберите еще один товар ",
        "SELECT_UPTO_SOME": "Выберите до {{max}} товаров ",
        "SELECT_BETWEEN": "Выберите еще от {{min}} и {{max}} товаров ",
    },
    "ACCESS": {
        "ACCESSIBILITY": "Меню доступности ",
        "ACCESSIBILITY_IN_PROGRESS": "Сайт в процессе доступа ",
        "CLEAR_ACCESSIBILITY_FEATURES": "Очистить параметры доступности ",
        "monochrom": "Монохромный ",
        "light-contrast": "Яркий контраст ",
        "dark-contrast": "Темный контраст ",
        "enlarge-font": "Увеличение шрифта ",
        "reduce-font": "Уменьшение шрифта ",
        "readable-font": "Разборчивый шрифт ",
        "big-white-curser": "Большой белый маркер ",
        "big-black-curser": "Большой черный маркер ",
        "enlarge-frame": "Увеличение ",
        "highlight-links": "Выделить ссылки ",
        "highlight-headers": "Выделить заголовки ",
        "image-info": "Описание к картинкам "
    },
    "ADDRESS_TYPES": {
        "apartment": "Квартира ",
        "office": "Офис ",
        "house": "Частный домי "
    },
    "OFFER_BADGES": {
        "new": "Новый ",
        "sale": "Распродажа "
    },
    "_WEIGHT": {
        "units": "единица ",
        "price_per_weight": "цена за {{unit}} = {{price}}",
        "item_weight": "Вес товара ",
        "kg": "(кг)",
        "gr": "гр (gr)",
        "lb": "фунт (lb)",
        "oz": "унция (oz)",
        "l": "литр (l)",
        "ml": "мл (ml)",
        "GRAM": "гр"
    },
    "_BENEFITS": {
        "BirthdayBenefit": "Бонус на День рождения ",
        "AnniversaryBenefit": "Бонус на годовщину свадьбы ",
        "JoinBenefit": "Бонус за присоединение ",
        "RenewBenefit": "Бонус за продление ",
        "Vouchers": "Ваучер ",
        "General": "Бонус",
        "Other": "Бонус",
        "PointsBenefit": "Накапливание баллов "
    },
    "_LOYALTY": {
        "cancel_benefits": "Отменить скидки ",
        "card_balance": "Карта загружена ",
        "connect": "Проверьте мне ",
        "continue": "продолжать ",
        "expires": "годен до ",
        "good_to_see_you_title": "Приятно тебя видеть ",
        "member_not_found_title": "Мы не нашли тебя ",
        "comps_extra_text": "Компенсация добавлена в заказ и может быть погашена.",
        "member_not_found": "Мы не нашли вас в списке членов клуба ",
        "member_not_found_PRIMARY_BUTTON_TEXT": "Подтвердить ",
        "member_expired": "Членство недействительно, бонусы не могут быть использованы ",
        "of": "от -",
        "points_balance": "оставшиеся {{points}}",
        "points_balance_header": "оставшиеся баллы ",
        "points_balance_cap": "У вас есть {{x}} баллов, которые можно немедленно использовать ",
        "basket_compensation": "Из них {{x}} шекелей – денежная компенсация",
        "punch_card_offer": "осталось {{from}} из {{to}}",
        "review_benefits": "Перечисление скидок ",
        "review_points": "Использовать баллы",
        "signout": "Отсоединиться ",
        "skip": "Пропустить ",
        "cancel": "Отменить ",
        "no_benefits_redeemed": "Бонусы в этой сделки не были реализованы!",
        "redeemed_benefits_title": "В этой сделке будут реализованы следующие бонусы ",
        "total_benefits": "Всего скидок ",
        "voucher_gtreeting": "Привет, у Вас есть ваучер?",
        "voucher_inline_gtreeting": "Привет, у Вас есть ваучер?",
        "voucher_gtreeting_info": "Хотите узнать о бонусах? Возможно, Вы заслуживаете чего-то стоящего ",
        "voucher_placeholder": "Номер ваучера ",
        "voucher_member_greeting": "Мои бонусы ",
        "voucher_benefits_title": "Мои бонусы ",
        "voucher_signin_error": "Ваучер с этим номером не найден ",
        "club_gtreeting": "Давайте проверим, есть ли у Вас льготы или членство в клубе ",
        "club_inline_gtreeting": "Введите код купона или номер члена клуба ",
        "club_gtreeting_info": "Хотите узнать о бонусах? Возможно, Вы заслуживаете чего-то стоящего ",
        "club_placeholder": "Номер телефона/ваучера ",
        "member_greeting": "Привет {{name}}",
        "club_benefits_title": "Мои бонусы ",
        "club_signin_error": "Ни один член клуба или ваучер с этим номером не найден. ",
        "signin_error_register": "Мы не нашли ваш номер в нашем списке участников<br/><br/><a target=_blank href=xxx>для вступления в клуб</a>",
        "select_club": "Выбрать клуб ",
        "benefits_title": "Мои бонусы ",
        "no_benefits_title": "Кажется, сейчас у Вас нет никаких льгот ",
        "register": "Присоединение ",
        "to_promotion_disclaimer": "Окончательная скидка будет рассчитана перед оплатой. ",
        "auth_title": "Требуется код верификации ",
        "auth_sub_title": "Код верификации отправлен на телефон ",
        "auth_code": "Код верификации ",
        "auth_resend": "Отправили еще раз ",
        "benefit_types": "Действительно только в течение {{val}} ",
        "max_redeemable_points": "Максимальное количество баллов для использования ",
        "my_benefits": "Мои бонусы ",
        "connect_info": "Номер телефона или номер карты ",
        "greeting": "Привет {{name}}!",
        "greeting_voucher": "Привет!",
        "balance": "баланс ",
        "hey_greeting": "Привет {{name}}!",
        "hey_greeting_pointsStore": "Привет, {{name}}, у тебя есть {{points}} {{pointName}}",
        "hey": "Привет!",
        "sum_to_use": "Сколько использовать?",
        "select_benefits_title": "Привет, {{name}}\ Как ты себя чувствуешь сегодня?",
        "select_benefits": "Редактирование бонусов ",
        "select_benefits_max": "Вы можете использовать до {{val}} бонусов в заказе",
        "benefits_max_in_this_order": "Вам предлагается воспользоваться <b>{{val}}</b> преимуществами по вашему выбору",
        "benefits_max_in_this_order_single": "Вам предлагается воспользоваться следующей привилегией:",
        "select_benefits_desc": "Вы должны убедиться, что товары, включенные в льготы, находятся в заказе.\  Бонусы будут реализованы до оплаты»",
        "selected_not_redeemed": "Не соблюдены условия для получения следующих льгот:",
        "selected_not_redeemed_desc": "Вам необходимо проверить содержимое заказа или выбрать другой бонус",
        "CLUB_BENEFITS_ON_NEXT_STAGE": "Ваши клубные льготы будут рассчитаны на следующем этапе. ",
        "GETTING_BENEFITS": "Калькулятор клубных льгот...",
        "POINTS_COMPS_PAY": "Финансовая компенсация",
        "POINTS_AND_COMPS_PAY": "И финансовая компенсация",
        "POINTS_COMPS": "В вашем распоряжении денежная компенсация в размере {{amount}} {{currency}} для использования 🎁",
        "POINTS_WAITING": "В вашем распоряжении {{amount}} {{caption}} для использования 🎁",
        "POINTS_AND_COMPS": "У вас есть {{points}} баллов для обмена (включая {{amount}} {{currency}} денежное вознаграждение) 🎁",
        "voucher_entered": "Ваучер введен",
        "registration_terms": "Регистрация является подтверждением возможности время от времени получать СМС-сообщения о выгодных преимуществах и акциях. Вы можете отказаться от подписки на рассылку в любое время",
        "regCostMessage": "Вступление в клуб предполагает оплату в размере {{currency}}{{price}}, которая будет добавлена в корзину товаров вашего заказа",
        "confirmDeleteOfferJoin": "Отказаться от клуба?",
        "confirmDeleteOfferRenewal": "Отменить продление членства в клубе?",
        "confirmDeleteOfferJoinDescription": "Отмена вступления в клуб лишит Вас возможности получать льготы»",
        "confirmDeleteOfferRenewalDescription": "Отмена продления членства в клубе помешает получению льгот",
        "removeOfferJoinButton": "Отмена вступления ",
        "removeOfferRenewalButton": "Отмена продления ",
        "keepOfferJoinButton": "продолжить с этого места ",
        "keepOfferRenewalButton": "продолжить с этого места ",
        "regTitle": "Члены клуба получают больше удовольствия!",
        "regDetailsDescription": "Мы не нашли ваш номер телефона {{phone}} в нашем списке. Пришло время присоединиться и воспользоваться преимуществами!",
        "regCancel": "Отмена",
        "continueReg": "Присоединение к клубу ",
        "regRenewMessage": "Продление членства в клубе стоит {{currency}}{{price}}, сумма будет добавлена в корзину товаров в вашем заказе.",
        "regPromptTitle": "Мы не смогли найти номер {{phone}} в нашем клубе.\ Пришло время вступить!",
        "regPromptPrice": "Вступление в клуб стоит {{currency}}{{price}}",
        "regUpdateNessage": "Пожалуйста, убедитесь, что введенные данные верны ",
        "registerButton": "Присоединение ",
        "continueWithoutRenewing": "Продолжить без продления ",
        "continueWithoutReg": "Продолжить, не присоединяясь ",
        "renewMembership": " Продлить членство в клубе ",
        "phoneNumber": "Номер мобильного телефона ",
        "textForConfirmSms": "Я разрешаю клубу время от времени присылать мне рекламные материалы посредством текстовых сообщений. Я могу удалить себя из списка рассылки в любое время.",
        "textForConfirmClubRules": "Подтверждаю регламент клуба ",
        "regRenewPromptPrice": "Продление членства в клубе стоит {{currency}}{{price}}",
        "regRenewTitle": "Время летит незаметно, когда вкусно!",
        "regRenewBody": "Пришло время продлить членство в нашем клубе ",
        "regSoonExpiredRenewBody": "Срок членства в клубе истек {{date}}.\nЧтобы и дальше пользоваться преимуществами клуба, вы можете продлить свое членство прямо сейчас!",
        "regSoonExpiredSignOut": "Срок членства в клубе истек {{date}}.",
        "termsContinue": "Перейдем к бонусам ",
        "termsBody": "Кажется, Вы еще не завершили регистрацию в клубе \ некоторые последние подробности и преимущества с Вами :)",
        "termsFirstContinue": "Полные данные ",
        "termsTitle": "Привет {{name}}",
        "first_name": "Имя ",
        "last_name": "Фамилия ",
        "email": "Электронная почта ",
        "zehut": "Удостоверение личности ",
        "phone": "Мобильный телефон ",
        "anniversary": "Дата свадьбы ",
        "birth_date": "Дата рождения ",
        "regErrorTitle": "Что-то пошло не так ",
        "regError": "Процесс присоединения не может быть завершен, необходимо обратиться в ресторан.",
    },
    "_GIFTCARD": {
        "title": "У Вас есть предоплаченная карта или подарочная карта, которую можно погасить?",
        "gift_card": "Подарочная карта ",
        "select_card": "Выберите карту ",
        "please_select_card": "Пожалуйста, выберите карту ",
        "edit_card": "Обновление карты ",
        "add_card": "Подтвердить ",
        "number": "Номер карты ",
        "amount": "Сумма к оплате ",
        "balance": "Остаток на карте ",
        "max": "Максимум для использования в этом заказе ",
        "cvv": "CVV",
        "cvv_required": "CVV необходимо заполнить ",
        "invalid_cvv": "Введенный номер CVV неверен",
        "inuse_error": "Одну и ту же карту нельзя активировать более одного раза за заказ. ",
        "balance_error": "На карте нет остатка ",
        "notfound_error": "Данные карты неверны или карта не существует в системе ",
        "delete_prompt": "Удалить карту из заказа?",
        "no_order_amount": "Оплата уже покрывает заказ, дополнительная оплата не требуется 😎 ",
        "reset_split_payments_prompt": "Добавление или обновление карты приведет к сбросу разделенных платежей! Продолжать?",
    },
    "_DELAYED": {
        "title": "Запланировать заказ ",
        "service_caption": "на другой день ",
        "supply_time": "дата доставки ",
        "select_date": "Пожалуйста, выберите дату доставки ",
        "select_slot": "Пожалуйста, выберите время доставки ",
        "service_today": "Заказ на сегодня ",
        "service_future": "Будущий заказ ",
        "for_hour": "на {{val}}",
        "for_date": "на {{val}}",
        "between": "между часами ",
        "at_hour": "в ",
        "select_slot_prompt": "Пожалуйста, выберите время доставки ",
        "service_disabled_today": "Сегодня данная услуга закрыта!"
    },
    "_OFFER_HISTORY": {
        "title": "Как приготовить блюдо?",
        "description": "В прошлом Вы выбрали ",
        "continue": "Выберите изменения еще раз ",
        "select": "Выберите блюдо "
    },
    "_EXTERNAL_DELIVERY": {
        "est_title": "Delivery Details",
        "est_apply": "Continue",
        "est_cancel": "Cancel",
        "delivery_time": "Estimated delivery time",
        "delivery_price": "Delivery price",
        "estimate_fail_message": "Delivery estimate failed",
        "validation_fail_message": "Delivery validation failed",
        "OUT_OF_DELIVERY_SERVICE_TIME": "Delivery is unavailable at this time",
        "ADDRESS_OUTSIDE_OF_DELIVERY_AREA": "We are sorry, delivery is not available for your address: {{address}}",
        "INVALID_PHONE": "Phone Number is invalid, please provide a valid phone number",
        "PHONE_NO_PSTN": "Phone Number is invalid, please provide a valid phone number",
        "ALCOHOL_NOT_DELIVERABLE": "Restaurant does not allow alcohol delivery. Remove alcohol from cart before proceeding to payment",
        "UNKNOWN_ERROR": "An error occured, please contact the restaurant"
    },
    "_EXTRA_INFO": {
        "delivery_ETA": "Время прибытия до ",
        "takeaway_ETA": "Время приготовления до ",
        "TAKEAWAY_FROM": "Откуда вы забираете??",
        "min_order_price": "Минимум для доставки ",
        "free_delivery_from": "Бесплатная доставка от -",
        "delivery_fee": "Стоимость доставки ",
        "up_to": "вплоть до ",
        "asap_takeaway_ETA": "Время приготовления ",
        "asap_delivery_ETA": "Время прибытия ",
        "future_delivery_ETA": "Планирование заказов",
        "delayed_delivery_ETA": "Предполагаемая дата ",
    },
    "SIGN_IN_REGISTER": "вход/регистрация ",
    "SIGN_IN_REGISTER_TABIT": "Подключение к Табиту ",
    "SIGN_UP_TABIT": "Зарегистрируйтесь в Табите ",
    "BASKET": {
        "INTERIM_AMOUNT": "Промежуточная сумма ",
        "TOTAL_ITEMS": "Всего товаров ",
        "FREE_DELIVERY_FROM_PREFIX": "Бесплатная доставка выше ",
        "FREE_DELIVERY_FROM_SUFFIX": "!",
        "MINIMUM_ORDER": "минимальный заказ {{price}}",
        "TO_FREE_DELIVERY": "Более {{amount}}",
        "FREE_DELIVERY": "Бесплатная доставка!",
        "CONGRATULATIONS": "Поздравления, ",
        "DELIVERY_ON_US": "Доставка за наш счет!",
        "TAKEAWAY_FROM": "Получение в ",
        "DELIVERY_TO": "Доставка в ",
        "EDIT_ORDER": "Обновить заказ",
        "DOES_NOT_INCLUDE_DELIVERY_FEE": "( Не включает стоимость доставки)",
        "DOES_NOT_INCLUDE_DELIVERY_FEE_AND_LOYALTY_ITEM": "( Не включает стоимость доставки ו{{item}})"
    },
    "BOTTOM_SHEET": {
        "pay_with_point": "Оплата баллами ",
        "regular_payment": "Стандартная оплата ",
    },
    "MENU_MESSAGES": "Сообщения ",
    "YOUR_DELIVERY_ORDER_TO": "Ваш заказ на доставку в",
    "YOUR_TA_ORDER_FROM": "Ваш заказ на доставку из ",
    "AMOUNT_LEFT": "Осталось {{количество}}",
    "ORDER_MINIMUM": "Минимальный заказ ",
    "REMINDER_AMOUNT": "{{amount}} осталось заплатить",
    "MY_ORDER": "Мой заказ ",
    "ORDER_SENT_SUCCESSFULLY": "Ваш заказ успешно отправлен!",
    "ORDER_SENT_INFO_TA": "Заказ самовывоза от {{номер}}, от {{сайт}} ({{адрес}})",
    "ORDER_SENT_INFO_DELIVERY": "Заказ на доставку номер {{number}} по адресу {{address}}",
    "DYNAMIC_ORDER_SUCCESS_MESSAGE": "Заказ на {{service}} от {{number}} успешно получен!",
    "DYNAMIC_ORDER_FAIL_MESSAGE": "Заказ {{service}} от {{number}} был отправлен в ресторан с ошибкой оплаты",
    "EATIN_SUCCESS_MESSAGE": "Заказ номер {{number}} успешно отправлен!",
    "ORDER_WILL_BE_READY_AT": "Будет готов к {{time}}.",
    "FUTURE_ORDER_WILL_BE_READY_AT": "Будет готов к {{time}}.",
    "FUTURE_ORDER_WILL_BE_READY": "Будет готов к -{{время}}.",
    "ORDER_WILL_BE_DELIVERED_AT": "Прибeltn к {{time}}.",
    "FUTURE_ORDER_WILL_BE_DELIVERED_AT": "Будет готов к {{time}}",
    "ASAP_FUTURE_ORDER_WILL_BE_DELIVERED_AT": "Будет готов к {{time}}.",
    "ENJOY_YOUR_MEAL": "Приятного аппетита!",
    "SLIDE_TO_PAY": "Проведите, чтобы оплатить ",
    "JUST_PAY": "Подтверждение об оплате ",
    "NATIVE_PAYMENT": {
        "error_dialog_title": "В настоящее время ваш запрос не может быть обработан.",
        "error_dialog_description": "Выберите другой способ оплаты ",
        "error_dialog_no_amount_description": "Чтобы оплатить с помощью {{type}} Pay, нажмите X в строке платежа и повторите попытку».",
    },
}
