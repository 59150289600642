import { ChangeDetectorRef, Injector, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { AppService } from '../app.service';

import { DomSanitizer } from '@angular/platform-browser';
import { padStart, get } from 'lodash-es';
import { TagsService } from './tags.service';
import { map } from 'rxjs/operators';
import { TranslationService } from './translation.service';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

@Pipe({ name: 'safeStyle' })
export class SafeStylePipe {
	constructor(private sanitizer: DomSanitizer) { }

	transform(url: string) {
		const imageUrl = `url('${url}')`;
		const sanitizedURL = this.sanitizer.bypassSecurityTrustStyle(imageUrl);
		return sanitizedURL;
	}
}

@Pipe({ name: 'safeURL' })
export class SafeURLPipe {
	constructor(private sanitizer: DomSanitizer) { }

	transform(val: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(val);
	}
}

@Pipe({
	name: 'lcurrency',
})
export class DefaultCurrencyPipe {
	constructor(
		private appService: AppService
    ) { }

	transform(val: string, digitsInfo?: string) {
        const currencyLocale = this.appService.appConfig.locale == 'he-IL' ? 'he' : 'en';
		const currency = new CurrencyPipe(currencyLocale).transform(val, null, this.appService.currency, digitsInfo);

        return currency;
	}
}

@Pipe({
	name: 'paymentExp',
})
export class PaymentExpiration {
	constructor() { }

	transform(val: any) {
		let m = val.expMonth
		let y = val.expYear;
		return padStart(m, 2, '0') + "/" + padStart(y, 2, '0')
	}
}

@Pipe({
    name: 'visualNumber',
})
export class VisualNumberPipe {
	constructor(
		private appService: AppService
	) { }

    transform(val: string) {
        return val;
		return (new CurrencyPipe('he')).transform(val, this.appService.currency);
	}
}

@Pipe({
	name: 'tmpTranslate',
	pure: false
})
export class TranslatePipe implements PipeTransform {
	constructor(private appService: AppService) {}
	transform(value: any, strOverrides?: any): any {
		let ret = this.appService.translate(value, null, strOverrides);
		return ret || value;
	}
}

@Pipe({
    name: 'AMPMConverter',
})
export class AMPMConverterPipe implements PipeTransform {
    constructor(private appService: AppService) { }
    transform(value: any): any {
        const safeValue = String(value).trim();
		if (this.appService.appConfig.locale.toLowerCase() === 'he-il') return safeValue;
        if (!safeValue.match(/^\d{1,2}:\d\d$/)) return safeValue;

        const hoursMinutes = safeValue.split(':').map(strNum => parseInt(strNum));
        let [ hours, minutes ] = hoursMinutes;
        if (hours > 12) return `${hours - 12}:${('00' + minutes).slice(-2)} pm`;
		else if (hours === 12) return `12:${('00' + minutes).slice(-2)} pm`;
        return `${hours === 0 ? '12' : hours}:${('00' + minutes).slice(-2)} am`;
    }
}

@Pipe({
	name: 'filterBy',
	pure: false
})
// https://github.com/VadimDez/ngx-filter-pipe
export class FilterPipe implements PipeTransform {

	static isFoundOnWalking(value, key) {
		let walker = value;
		let found = false;
        if (!walker) return found;
		do {
			if (walker.hasOwnProperty(key) || Object.getOwnPropertyDescriptor(walker, key)) {
				found = true;
				break;
			}
		} while (walker = Object.getPrototypeOf(walker));

		return found;
	}

	static isNumber(value) {
		return !isNaN(parseInt(value, 10)) && isFinite(value);
	}

	/**
	 * Checks function's value if type is function otherwise same value
	 */
	static getValue(value: any): any {
		return typeof value === 'function' ? value() : value;
	}

	private filterByString(filter) {
		if (filter) {
			filter = filter.toLowerCase();
		}
		return value => !filter || (value ? ('' + value).toLowerCase().indexOf(filter) !== -1 : false);
	}

	private filterByBoolean(filter) {
		return value => Boolean(value) === filter;
	}

	private filterByObject(filter) {
		return value => {
			for (const key in filter) {

				if (key === '$or') {
					if (!this.filterByOr(filter.$or)(FilterPipe.getValue(value))) {
						return false;
					}
					continue;
				}

				if (!FilterPipe.isFoundOnWalking(value, key)) {
					return false;
				}

				if (!this.isMatching(filter[key], FilterPipe.getValue(value[key]))) {
					return false;
				}
			}

			return true;
		};
	}

	private isMatching(filter, val) {
		switch (typeof filter) {
			case 'boolean':
				return this.filterByBoolean(filter)(val);
			case 'string':
				return this.filterByString(filter)(val);
			case 'object':
				return this.filterByObject(filter)(val);
		}
		return this.filterDefault(filter)(val);
	}

	/**
	 * Filter value by $or
	 */
	private filterByOr(filter: any[]): (value: any) => boolean {
		return (value: any) => {
			const length = filter.length;

			const arrayComparison = (i) => value.indexOf(filter[i]) !== -1;
			const otherComparison = (i) => value === filter[i];
			const comparison = Array.isArray(value) ? arrayComparison : otherComparison;

			for (let i = 0; i < length; i++) {
				if (comparison(i)) {
					return true;
				}
			}

			return false;
		};
	}

	/**
	 * Default filterDefault function
	 */
	private filterDefault(filter: any): (value: any) => boolean {
		return (value: any) => filter === undefined || filter == value;
	}

	transform(array: any[], filter: any): any {
		if (!array) {
			return array;
		}

		switch (typeof filter) {
			case 'boolean':
				return array.filter(this.filterByBoolean(filter));
			case 'string':
				if (FilterPipe.isNumber(filter)) {
					return array.filter(this.filterDefault(filter));
				}
				return array.filter(this.filterByString(filter));
			case 'object':
				return array.filter(this.filterByObject(filter));
			case 'function':
				return array.filter(filter);
		}
		return array.filter(this.filterDefault(filter));
	}
}

@Pipe({
    name: 'weight',
    pure: false
})

export class WeightPipe implements PipeTransform {
    constructor(private appService: AppService) { }
    transform(value: any, unit: string = 'kg'): any {
        switch (unit) {
            case "kg":
                value *= 1000;
                unit = this.appService.translate('_WEIGHT.GRAM');
                break;
        }
        return `${value} ${unit}`;
    }
}

@Pipe({
    name: 'translateSiteTagsAsync',
    pure: false
})
export class TranslateSiteTagsAsync implements PipeTransform {
	private asyncPipe: AsyncPipe;
    
	constructor(private tagsService: TagsService, private injector: Injector) { 
		this.asyncPipe = new AsyncPipe(this.injector.get(ChangeDetectorRef))
	}
    
	transform(tagsId: string[]): any {
		if (!tagsId) return [];
		
		return this.asyncPipe.transform(this.tagsService.getTags().pipe(map(tags => {
			tags = tags || [];
			return tagsId.map(tagId => this.tagsService.getTagName(tagId)).filter(tag => tag);;
		})));
	}
}

@Pipe({
	name: 'areaDescriptionResolver',
	pure: false
})
export class SittingsAreaDescriptionPipe implements PipeTransform {
	constructor(private translationService: TranslationService) { }
	transform(value: any, key: string): any {
		const lang = this.translationService.getLastUsedLang();
		if (!value && !key && !lang) {
			return '';
		}

		return get(value, `rsv["${lang}"].booking.search["${key}_description"]`, '');
	}
}

@Pipe({
	name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
	constructor(private appService: AppService) { }
	transform(
		phone: string | number,
		countryCode: CountryCode = this.appService.appConfig.countryCode || 'IL', 
		format: 'national' | 'international' | 'E.164' = 'national'): string {
		if (!phone) return '';

		try {
			const phoneStr = phone.toString(); // Convert phone to string
			const phoneNumber = parsePhoneNumberFromString(phoneStr, countryCode);
			if (!phoneNumber) return phoneStr;

			switch (format) {
				case 'national':
					// National format: e.g., 054-123-4567 (local format for Israel)
					return phoneNumber.formatNational();

				case 'international':
					// International format: e.g., +972 54 123 4567
					return phoneNumber.formatInternational();

				case 'E.164':
					// E.164 format: e.g., +972541234567 (standard for systems)
					return phoneNumber.format('E.164');
				default:
					return phoneStr;
			}
		} catch {
			return phone.toString();
		}
	}
}
