const { environment } = require('../../../environments/environment');
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../../app.service'
import { OnlineReservationsService } from '../../online-reservations/online-reservations.service';
import { DialogsService } from '../../_core/dialogs.service';

import moment from 'moment';

@Component({
    selector: 'app-list-dialog',
    templateUrl: './receipts-dialog.component.html',
    styleUrls: ['./receipts-dialog.component.scss']
})
export class ReceiptsDialogComponent implements OnInit {

    private appConfig: any = environment.appConfig;
    public receipts: any[] = [];
    public currencySymbol: string;

    constructor(
        public appService: AppService,
        public dialogRef: MatDialogRef<ReceiptsDialogComponent>,
        public onlineReservationsService: OnlineReservationsService,
        private dialogsService: DialogsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit() {
        this.receipts = this.data?.payments.map(payment => {
            return {
                paymentType: payment.type,
                url: this.generateReceiptUrl(this.data?.organizationId, payment.order),
                amount: (payment.amount / 100).toFixed(2),
                currencySymbol: this.data?.currencySymbol,
                date: moment(payment.date).locale(this.appService.localeId).format('LL')
            };
        });

        this.currencySymbol = this.data?.currencySymbol;
    }

    private generateReceiptUrl(orgId: string, orderId: string): string {
        return `${this.appConfig.tabitDocumentsURL}/receipt?o=${orgId}&oid=${orderId}`;
    }

    public receiptClick(receiptUrl: string): void {
        this.dialogsService.toggleActionFrame('link', null, null, window['cordova'], receiptUrl);
    }
}
